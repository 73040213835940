// Standard library imports
import React, { useContext, useEffect, useState } from 'react';

// External library imports
import { UilPlayCircle, UilStopCircle } from '@iconscout/react-unicons';
import { toast } from 'react-toastify';
import { get as LodashGet } from 'lodash';

// Internal module imports
import Typography from '../../../components/Typography/Typography';
import Table from '../../../components/Table/Table';
import { DownloadAsExcel } from '../../../utils/downloadAsExcel';
import DownloadButton from '../../../components/Buttons/DownloadButton';
import CustomTooltip from '../../../components/CustomToolTip/CustomTooltip';
import { CamelCaseToTextCase } from '../../../utils/stringHelper';
import { momentTimeFormater } from '../../../utils/dateHelper';
import ModalComponent from '../../../components/ModalComponent/ModalComponent';
import { DropdownComponent } from '../../../components/Inputs/Input';
import { EVCService } from '../../../services/EVCService';
import { START_LOADER, STOP_LOADER, USER_ROLE } from '../../../constants';
import { LoaderContext } from '../../../context/LoaderContext';
import { AuthContext } from '../../../context/AuthContext';

// Css module imports
import classes from '../../../styles/AllDevices.module.css';
import myClasses from '../EVCharging.module.css';

const AllChargers = ({ devices }) => {

    const { dispatch: loaderDispatch } = useContext(LoaderContext);
    const startLoader = (payload) => loaderDispatch({ type: START_LOADER, payload });
    const stopLoader = (payload) => loaderDispatch({ type: STOP_LOADER, payload });
    const { state } = useContext(AuthContext);
    const userRole = LodashGet(state, 'user.userRole');
    const [instances, setInstances] = useState([]);
    const [connectors, setConnectors] = useState([]);
    const [selectedConnector, setSelectedConnector] = useState({});
    const [remoteOpen, setRemoteOpen] = useState({ status: false, data: '', type: '' });

    useEffect(() => {
        if (devices?.length) {
            handleInstance();
        }
    }, [devices?.length]);

    useEffect(() => {
        const processedData = [];
        if (remoteOpen?.status && remoteOpen?.data?.meta?.connectors) {
            for (let i = 1; i <= remoteOpen?.data?.meta?.connectors; i++) {
                processedData.push({
                    label: `Connector ${i}`,
                    value: i
                })
            }
        }
        setConnectors(processedData);
        if (processedData.length) {
            setSelectedConnector(processedData[0]);
        } else {
            setSelectedConnector({});
        }
    }, [remoteOpen?.status])

    const getActions = (item) => {
        return (
            <div className={myClasses.ActionIcons}>
                <div onClick={() => { setRemoteOpen({ status: true, data: item, type: 'start' }) }}>
                    <CustomTooltip content={'Remote Start'}>
                        <UilPlayCircle size="1.5vw" className={myClasses.StartIcon} />
                    </CustomTooltip>
                </div>
                <div onClick={() => setRemoteOpen({ status: true, data: item, type: 'stop' })}>
                    <CustomTooltip content={'Remote Stop'}>
                        <UilStopCircle size="1.5vw" className={myClasses.StopIcon} />
                    </CustomTooltip>
                </div>
            </div>
        );
    };

    const handleInstance = () => {
        const processedData = devices.map((instance, index) => {
            return {
                's.no': index + 1,
                name: instance.name,
                description: !instance?.description ? (
                    '--'
                ) : instance.description.length > 40 ? (
                    <CustomTooltip content={instance.description}>{instance.description.substring(0, 40) + '...'}</CustomTooltip>
                ) : (
                    instance.description
                ),
                chargerId: instance?.meta?.serialNumber ? instance.meta.serialNumber : '--',
                connectors: instance?.meta?.connectors ? instance.meta.connectors : '--',
                chargerType: instance?.meta?.chargerType ? CamelCaseToTextCase(instance.meta.chargerType) : '--',
                active: instance?.meta?.isActive ? instance.meta.isActive : false,
                licenseType: 'EV Charging',
                id: instance._id,
                createdAt: instance.createdAt ? momentTimeFormater(instance.createdAt).format('YYYY-MM-DD HH:mm:ss') : '--',
                action: getActions(instance)
            };
        });
        setInstances(processedData);
    };

    const handleRemote = () => {
        console.log("log handleRemote", remoteOpen);
        const { data, type } = remoteOpen;
        if (type === 'start') {
            EVCService.RemoteStart(
                {
                    chargerId: data.meta.serialNumber,
                    connectorId: selectedConnector.value
                },
                () => startLoader('getRemote'),
                handleRemoteSuccess,
                handleError,
                () => stopLoader('getRemote')
            );
        } else if (type === 'stop') {
            EVCService.RemoteStop(
                {
                    chargerId: data.meta.serialNumber,
                    connectorId: selectedConnector.value
                },
                () => startLoader('getRemote'),
                handleRemoteSuccess,
                handleError,
                () => stopLoader('getRemote')
            );
        }
    }

    const handleRemoteSuccess = ({ data }) => {
        setRemoteOpen({ status: false, data: '', type: '' })
        toast.success(data?.data?.status);
    }

    const handleError = (err) => {
        let data = err && err.response ? err.response.data : null;
        if (data) toast.error(data.message);
        else toast.error('Internal server error!');
    };

    const handleExport = () => {
        const processedData = [];
        devices.map((instance) => {
            processedData.push({
                name: instance.name,
                description: instance.description,
                licenseType: 'EV Charging',
                chargerId: instance?.meta?.serialNumber,
                connectors: instance?.meta?.connectors,
                chargerType: instance?.meta?.chargerType,
                createdAt: instance.createdAt ? momentTimeFormater(instance.createdAt).format('YYYY-MM-DD HH:mm:ss') : ''
            });
        });
        DownloadAsExcel(processedData, 'EV_Charging_Instance', ['Name', 'Description', 'License Type', 'Charger ID', 'Connectors', 'Charger Type', 'Created At (CET)']);
    };
    return (
        <div className={classes.AllDevices}>
            <div className={classes.Header}>
                <div>
                    <Typography content="All Chargers" />
                    <div className={classes.TableCount}>
                        Total Count :
                        <span>
                            <Typography size="14" content={devices.length} />
                        </span>
                    </div>
                </div>
                <div style={{ display: 'flex', alignItems: 'center' }}>
                    <div>
                        <DownloadButton size="medium" onClick={() => handleExport()} />
                    </div>
                </div>
            </div>
            <div>
                <Table
                    head={['S.No', 'Name', 'Description', 'License Type', 'Charger ID', 'Connectors', 'Charger Type', 'Created At (CET)',
                        [USER_ROLE.SUDOER, USER_ROLE.EXPERT].includes(userRole) ? 'Action' : ''
                    ]}
                    keys={['s.no', 'name', 'description', 'licenseType', 'chargerId', 'connectors', 'chargerType', 'createdAt',
                        [USER_ROLE.SUDOER, USER_ROLE.EXPERT].includes(userRole) ? 'action' : ''
                    ]}
                    data={instances}
                />
            </div>

            <ModalComponent isOpen={remoteOpen.status} setOpen={() => setRemoteOpen({ status: false, data: '', type: '' })}>
                <Typography content={`Remote ${remoteOpen.type === 'start' ? 'Start' : 'Stop'}`} />
                <div className={myClasses.FieldControl}>
                    <label>Connector</label>
                    <DropdownComponent
                        name="connector"
                        options={connectors}
                        defaultValue={selectedConnector}
                        onChange={(e) => setSelectedConnector(e)}
                    />
                </div>

                <div className={classes.ButtonContainer}>
                    <div>
                        <div className="btn-secondary" onClick={() => setRemoteOpen({ status: false, data: '', type: '' })}>
                            Cancel
                        </div>
                    </div>
                    <div>
                        <button className="btn-primary" onClick={() => handleRemote()}>
                            Submit
                        </button>
                    </div>
                </div>
            </ModalComponent>
        </div>
    );
};

export default AllChargers;
