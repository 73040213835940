// Standard library imports
import React, { useState } from 'react';

// Internal module imports
import TabsComponent from '../../../components/Tabs/Tabs';
import Tags from './Tags';
import Groups from './Groups';

// Css module imports
import classes from '../../../styles/Alerts.module.css';

const TagTabs = ({ updateQueryParams, queryParamsData }) => {

    const [selectedIndex, setSelectedIndex] = useState(0);

    const tabs = [
        {
            name: 'Tag',
            component: (
                <Tags
                    updateQueryParams={updateQueryParams}
                    queryParamsData={queryParamsData}
                />
            ),
        },
        {
            name: 'Group',
            component: <Groups
                updateQueryParams={updateQueryParams}
                queryParamsData={queryParamsData}
            />,
        },
    ];
    return (
        <div className={classes.Alert + ' ' + classes.ContainerBorderRadius}>
            <TabsComponent tabs={tabs} selectedIndex={selectedIndex} onChangeTab={(index) => updateQueryParams('subTab', index)} />
        </div>
    );
};

export default TagTabs;
