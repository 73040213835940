// Standard library imports
import React, { useContext, useState } from 'react';

// Extenal library imports
import { UilSitemap } from '@iconscout/react-unicons'

// Internal module imports
import CollapsibleWithRename from '../../../components/CollapsibleWithRename/CollapsibleWithRenameForGroup';

// Css imports
import classes from '../../../styles/DeviceList.module.css';

const DeviceList = ({ instances, setSelectedInstance, updateQueryParams, queryParamsData }) => {
    const [collapsibleOpen, setCollapsibleOpen] = useState('Chargers');

    return (
        <div className={classes.Details}>
            <div></div>
            <div className={classes.CollapsibleContainer}>
                {instances && (
                    <CollapsibleWithRename
                        open={collapsibleOpen}
                        setOpen={setCollapsibleOpen}
                        setCreateGroupModal={() => console.log("createGroupModal")}
                        group={instances}
                        setDevice={setSelectedInstance}
                        deviceIcon={<UilSitemap size="1.2vw" style={{ color: 'var(--color-primary)' }} />}
                        title='Chargers'
                    />
                )}
            </div>
        </div>
    );
};

export default DeviceList;
