import { HttpClient } from '../utils/httpClient';

const PATH = {
    instanceCapacity: '/client/data/instance-capacity',
    lastReported: '/client/data/instance-capacity/last-reported',
};

const GetInstanceCapacity = (params, start, callback, error, next) => {
    start();
    return HttpClient.get(`${PATH.instanceCapacity}`, { params }).then(callback).catch(error).finally(next);
};

const LastReported = (params, start, callback, error, next) => {
    start();
    return HttpClient.get(`${PATH.lastReported}`, { params }).then(callback).catch(error).finally(next);
};

export const BidMarketService = {
    GetInstanceCapacity,
    LastReported,
};
