// Standard library imports
import React, { useState, useContext, useEffect } from 'react';

// External library imports
import moment from 'moment/moment';

// Internal module imports
import ModalComponent from '../../../../components/ModalComponent/ModalComponent';
import Typography from '../../../../components/Typography/Typography';
import { Form, Formik } from 'formik';
import Dropdown from '../../../../components/Inputs/Dropdown';
import DateRangePicker from '../../../../components/Inputs/DateRangePicker/DateRangePicker';
import { START_LOADER, STOP_LOADER } from '../../../../constants';
import { LoaderContext } from '../../../../context/LoaderContext';
import { DownloadAsExcel } from '../../../../utils/downloadAsExcel';

// Css imports
import classes from './ExportModal.module.css';
import { NordpoolService } from '../../../../services/NordpoolService';
import { momentTimeFormater } from '../../../../utils/dateHelper';

const ExportModal = ({ isOpen, setOpen, signalOptions }) => {
    const [signalOptionsDrp, setSignalOptionsDrp] = useState([]);
    const [selectedSignal, setSelectedSignal] = useState({});
    const { dispatch: loaderDispatch } = useContext(LoaderContext);
    const startLoader = (payload) => loaderDispatch({ type: START_LOADER, payload });
    const stopLoader = (payload) => loaderDispatch({ type: STOP_LOADER, payload });
    const [dateRange, setDateRange] = useState({
        startDate: new Date(),
        endDate: new Date(),
    });

    useEffect(() => {
        setSignalOptionsDrp(signalOptions?.length ? signalOptions : []);
        setSelectedSignal(signalOptions?.length ? signalOptions[0] : {})
    }, [signalOptions])

    const initialValues = {
        signal: selectedSignal?.value,
        date: dateRange,
    };

    const handleSubmit = (values) => {

        const params = {
            signal: values.signal,
            startDate: moment(dateRange.startDate).format('YYYY-MM-DD'),
            endDate: moment(dateRange.endDate).format('YYYY-MM-DD'),
        };

        if (values.signal) {
            NordpoolService.downloadBulkData(
                params,
                () => startLoader('downloadBulkData'),
                handleDownloadSuccess,
                handleError,
                () => stopLoader('downloadBulkData')
            );
        }
    };

    const handleDownloadSuccess = ({ data }) => {
        const excelData = data?.data?.map((item) => {
            return {
                startDate: momentTimeFormater(item.start_time).format('YYYY-MM-DD'),
                startTime: momentTimeFormater(item.start_time).format('HH:mm'),
                endDate: momentTimeFormater(item.end_time).format('YYYY-MM-DD'),
                endTime: momentTimeFormater(item.end_time).format('HH:mm'),
                price: item.price,
            }
        });

        if (excelData) {
            DownloadAsExcel(excelData, `Prices ${selectedSignal.value} ${moment(dateRange.startDate).format('YYYY-MM-DD')} - ${moment(dateRange.endDate).format('YYYY-MM-DD')}`, ['Start Date (CET)', 'Start Time (CET)', 'End Date (CET)', 'End Time (CET)', 'Price (DKK)']);
        }
    };

    const handleError = (err) => console.log(err);

    return (
        <div>
            <ModalComponent isOpen={isOpen} setOpen={setOpen} style={{ overflow: 'initial' }}>
                <div>
                    <Typography content={'Bulk Download Excel file'} />

                    <div style={{ marginTop: '2vw' }}>
                        <Formik enableReinitialize initialValues={initialValues} onSubmit={(val, { resetForm }) => handleSubmit(val, resetForm)}>
                            {({ errors, touched, values, isValidating, ...props }) => {
                                return (
                                    <Form>
                                        <div className={classes.InputContainer} style={{ display: 'flex', flexDirection: 'column', gap: '0.5vw', alignItems: 'center' }}>
                                            <div className={classes.FieldControl}>
                                                <label>Select Signal</label>
                                                <Dropdown name="signal" options={signalOptionsDrp} defaultValue={selectedSignal} onChange={setSelectedSignal} />
                                            </div>
                                            <div className={classes.FieldControl} style={{ marginTop: '8px' }}>
                                                <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                                                    <label>Start Date</label>
                                                    <label style={{ marginRight: '2.604vw' }}>End Date</label>
                                                </div>
                                                <DateRangePicker
                                                    startDate={dateRange.startDate}
                                                    endDate={dateRange.endDate}
                                                    onChange={(date) => setDateRange({ startDate: new Date(date.startDate), endDate: new Date(date.endDate) })}
                                                    style={{ height: '2.5vw', width: '100%' }}
                                                />
                                            </div>
                                        </div>

                                        <div className={classes.ButtonContainer}>
                                            <div>
                                                <div className="btn-secondary" onClick={() => setOpen(false)}>
                                                    Cancel
                                                </div>
                                            </div>
                                            <div>
                                                <button type="submit" className="btn-primary">
                                                    Submit
                                                </button>
                                            </div>
                                        </div>
                                    </Form>
                                );
                            }}
                        </Formik>
                    </div>
                </div>
            </ModalComponent>
        </div>
    );
};

export default ExportModal;
