// Standard library imports
import React, { useContext, useEffect, useState } from 'react';

// External library imports
import moment from 'moment-timezone';
import { UilImport } from '@iconscout/react-unicons';

// Internal module imports
import { NordpoolService } from '../../../services/NordpoolService';
import { LoaderContext } from '../../../context/LoaderContext';
import { START_LOADER, STOP_LOADER } from '../../../constants';
import { momentTimeFormater, timeOnly } from '../../../utils/dateHelper';
import { DropdownComponent } from '../../../components/Inputs/Input';
import ExportModal from './ModalComponent/ExportModal';
import ChartComponent from '../../../components/ApexCharts/Chart';
import DatePicker from '../../../components/Inputs/DatePicker/DatePicker';
import DownloadButton from '../../../components/Buttons/DownloadButton';
import { DownloadAsExcel } from '../../../utils/downloadAsExcel';

// Css imports
import classes from '../DemandResponse.module.css';

const ChartContainer = ({ market, updateQueryParams, queryParamsData }) => {
    const [graphData, setGraphData] = useState([]);
    const [downloadData, setDownloadData] = useState([]);
    const [date, setDate] = useState(queryParamsData.date ? queryParamsData.date : moment().subtract(1, 'day').format());
    const [lastStatus, setLastStatus] = useState('--');
    const { dispatch: loaderDispatch } = useContext(LoaderContext);
    const startLoader = (payload) => loaderDispatch({ type: START_LOADER, payload });
    const stopLoader = (payload) => loaderDispatch({ type: STOP_LOADER, payload });
    const [showExportModal, setShowExportModal] = useState(false);

    useEffect(() => {
        updateQueryParams('date', date);
    }, [date]);

    const SIGNAL_OPTIONS = [
        ...(market === 'FCR-D-INC'
            ? [
                {
                    label: 'FCR-D Increased DK2',
                    value: 'FCR_D_INCREASED_DK2',
                },
                {
                    label: 'FCR-D Increased SE1',
                    value: 'FCR_D_INCREASED_SE1',
                },
                {
                    label: 'FCR-D Increased SE2',
                    value: 'FCR_D_INCREASED_SE2',
                },
                {
                    label: 'FCR-D Increased SE3',
                    value: 'FCR_D_INCREASED_SE3',
                },
                {
                    label: 'FCR-D Increased SE4',
                    value: 'FCR_D_INCREASED_SE4',
                },
            ]
            : []),
        ...(market === 'FCR-D-DEC' || market === 'FCR-D-'
            ? [
                {
                    label: 'FCR-D Decreased DK2',
                    value: 'FCR_D_DECREASED_DK2',
                },
                {
                    label: 'FCR-D Decreased SE1',
                    value: 'FCR_D_DECREASED_SE1',
                },
                {
                    label: 'FCR-D Decreased SE2',
                    value: 'FCR_D_DECREASED_SE2',
                },
                {
                    label: 'FCR-D Decreased SE3',
                    value: 'FCR_D_DECREASED_SE3',
                },
                {
                    label: 'FCR-D Decreased SE4',
                    value: 'FCR_D_DECREASED_SE4',
                },
            ]
            : []),
        ...(market === 'FCR'
            ? [
                {
                    label: 'FCR DK1',
                    value: 'FCR_DK1',
                },
            ]
            : []),
        ...(market === 'aFRR'
            ? [
                {
                    label: 'AFRR DECREASED DK2',
                    value: 'AFRR_DECREASED_DK2',
                },
                {
                    label: 'AFRR INCREASED DK2',
                    value: 'AFRR_INCREASED_DK2',
                },
            ]
            : []),
        ...(market === 'aFRR-DK1-DEC'
            ? [
                {
                    label: 'AFRR DECREASED DK1',
                    value: 'AFRR_DECREASED_DK1',
                }
            ]
            : []),
        ...(market === 'aFRR-DK2-DEC'
            ? [
                {
                    label: 'AFRR DECREASED DK2',
                    value: 'AFRR_DECREASED_DK2',
                },
            ]
            : []),
        ...(market === 'aFRR-DK1-INC'
            ? [
                {
                    label: 'AFRR INCREASED DK1',
                    value: 'AFRR_INCREASED_DK1',
                }
            ]
            : []),
        ...(market === 'aFRR-DK2-INC'
            ? [
                {
                    label: 'AFRR INCREASED DK2',
                    value: 'AFRR_INCREASED_DK2',
                },
            ]
            : []),
        ...(market === 'mFRR'
            ? [
                {
                    label: 'MFRR INCREASED DK1',
                    value: 'MFRR_INCREASED_DK1',
                },
                {
                    label: 'MFRR DECREASED DK1',
                    value: 'MFRR_DECREASED_DK1',
                },
                {
                    label: 'MFRR INCREASED DK2',
                    value: 'MFRR_INCREASED_DK2',
                },
                {
                    label: 'MFRR DECREASED DK2',
                    value: 'MFRR_DECREASED_DK2',
                },
            ]
            : []),
    ];
    const PRICE_TYPE_OPTIONS = [
        {
            label: 'Total',
            value: 'total',
        },
        {
            label: 'D-1',
            value: 'D-1',
        },
        {
            label: 'D-2',
            value: 'D-2',
        },
    ];
    const [selectedSignal, setSelectedSignal] = useState(queryParamsData.signal ? queryParamsData.signal : SIGNAL_OPTIONS?.length ? SIGNAL_OPTIONS[0] : {});
    const [selectedPriceType, setSelectedPriceType] = useState(queryParamsData.priceType ? queryParamsData.priceType : PRICE_TYPE_OPTIONS[0]);
    useEffect(() => {
        if (selectedSignal?.value) {
            let processedDate = moment(date).tz('Europe/Berlin').format('YYYY-MM-DD');
            let signal = {
                signal: selectedSignal?.value,
            };
            NordpoolService.getPricing(
                signal.signal,
                market === 'FCR-D-INC' || 'FCR-D-DEC' || 'FCR-D-' ? selectedPriceType.value : 'total',
                processedDate,
                () => startLoader('getData'),
                handleGetDataSuccess,
                handleError,
                () => stopLoader('getData')
            );

            NordpoolService.lastReported(
                signal,
                () => startLoader('getData'),
                handleLastReportedSuccess,
                handleError,
                () => stopLoader('getData')
            );
        }
    }, [date, selectedSignal, selectedPriceType]);

    const handleGetDataSuccess = ({ data }) => {
        setGraphData(data.data.graph);
        setDownloadData(data.data.download);
    };
    const handleLastReportedSuccess = ({ data }) => {
        const ndate = moment(data.data.price.start_time).tz('Europe/Berlin').format('YYYY-MM-DD');
        setLastStatus(ndate);
    };

    const handleError = (err) => console.log(err);

    const handleExport = () => {
        const excelData = downloadData.map((item) => {
            return {
                startDate: momentTimeFormater(item.start_time).format('YYYY-MM-DD'),
                startTime: momentTimeFormater(item.start_time).format('HH:mm'),
                endDate: momentTimeFormater(item.end_time).format('YYYY-MM-DD'),
                endTime: momentTimeFormater(item.end_time).format('HH:mm'),
                price: item.price,
            }
        });

        if (excelData) {
            DownloadAsExcel(excelData, `Prices ${selectedSignal.value} ${moment(date).format('YYYY-MM-DD')}`, ['Start Date (CET)', 'Start Time (CET)', 'End Date (CET)', 'End Time (CET)', 'Price (DKK)']);
        }
    };

    const rightSideComponent = (
        < div
            style={{
                display: 'flex',
                alignItems: 'center',
                gap: '0.5vw',
                paddingRight: '1vw'
            }
            }
        >
            <DatePicker date={date} setDate={setDate} startDate={null} />
            <DownloadButton size="meduim" onClick={handleExport} />
        </div >
    )

    return (
        <div>
            {SIGNAL_OPTIONS?.length > 0 && (
                <div className={classes.FilterContainer}>
                    <div style={{ display: 'flex', justifyContent: 'flex-start', gap: '1vw' }}>
                        <div className={classes.FieldControl} style={{ margin: '0' }}>
                            <label for="signal">Signal</label>
                            <DropdownComponent
                                defaultValue={queryParamsData.signal ? queryParamsData.signal : SIGNAL_OPTIONS[0]}
                                options={SIGNAL_OPTIONS}
                                onChange={(e) => {
                                    setSelectedSignal(e);
                                    updateQueryParams('signal', JSON.stringify(e));
                                }}
                            />
                        </div>
                        {(market === 'FCR-D-INC' || market === 'FCR-D-DEC' || market === 'FCR-D-') && (
                            <div className={classes.FieldControl} style={{ margin: '0' }}>
                                <label for="signal">Type</label>
                                <DropdownComponent
                                    defaultValue={selectedPriceType}
                                    options={PRICE_TYPE_OPTIONS}
                                    onChange={(e) => {
                                        setSelectedPriceType(e);
                                        updateQueryParams('priceType', JSON.stringify(e));
                                    }}
                                />
                            </div>
                        )}
                    </div>
                    <UilImport size="1.9vw" style={{ color: 'var(--color-primary)', cursor: 'pointer' }} onClick={() => setShowExportModal(true)} />
                </div>
            )}

            <ExportModal isOpen={showExportModal} setOpen={setShowExportModal} signalOptions={SIGNAL_OPTIONS} />

            <div className={classes.BottomContainer}>
                <ChartComponent
                    series={graphData}
                    title={`${market} Pricing (DKK/MW)`}
                    xFormatter={timeOnly}
                    type="line"

                    curve="stepline"
                    lastReported={lastStatus}
                    rightSideElement={rightSideComponent}
                />
            </div>
        </div>
    );
};

export default ChartContainer;
