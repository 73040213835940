// Standard library imports
import React, { useContext, useEffect, useState } from 'react';

// External library imports
import { useLocation, useHistory } from 'react-router-dom';

// Internal module imports
import TabsComponent from '../../components/Tabs/Tabs';
import { AuthContext } from '../../context/AuthContext';
import { LoaderContext } from '../../context/LoaderContext';
import { START_LOADER, STOP_LOADER } from '../../constants';
import TagTabs from './Tags/TagTabsComponent';
import Details from './Details/Details';
import { EVCService } from '../../services/EVCService';
import { sortByName } from '../../utils/objectHelper';
import AllChargers from './AllChargers/AllChargers';
import Transactions from './Transactions/Transactions';

// Css module imports
import classes from './EVCharging.module.css';

const EVCharging = () => {
    const location = useLocation();
    const history = useHistory();
    const queryParams = new URLSearchParams(location.search);

    const { state } = useContext(AuthContext);
    const licenseTypeId = state.isAuthorized ? queryParams.get('licenseTypeId') : '';

    const { dispatch: loaderDispatch } = useContext(LoaderContext);
    const startLoader = (payload) => loaderDispatch({ type: START_LOADER, payload });
    const stopLoader = (payload) => loaderDispatch({ type: STOP_LOADER, payload });

    const [queryParamsData] = useState({
        subTab: queryParams.get('subTab') ? Number(queryParams.get('subTab')) : '',
        tab: queryParams.get('tab') ? Number(queryParams.get('tab')) : '',
        limit: queryParams.get('limit') ? Number(queryParams.get('limit')) : '',
        date: queryParams.get('date') ? new Date(queryParams.get('date')) : '',
        createTagModal: queryParams.get('createTagModal') && queryParams.get('createTagModal') === 'true' ? Boolean(queryParams.get('createTagModal')) : false,
    });
    const [open, setOpen] = useState(false);
    const [selectedOuterIndex, setSelectedOuterIndex] = useState(queryParamsData.tab ? queryParamsData.tab : 0);

    const updateQueryParams = (type, value) => {
        let queryParamsNew = queryParams;
        if (type === 'tab') {
            const licenseTypeId = queryParamsNew.get('licenseTypeId');
            queryParamsNew = new URLSearchParams();
            queryParamsNew.set('licenseTypeId', licenseTypeId);
        }
        queryParamsNew.set(type, value);
        history.push({ search: queryParamsNew.toString() });
    };
    const [instances, setInstances] = useState([]);
    const [selectedInstance, setSelectedInstance] = useState({});


    useEffect(() => {
        if (licenseTypeId) {
            EVCService.getLicenseTypeDetails(
                licenseTypeId,
                () => startLoader('getInstances'),
                handleGetInstanceSuccess,
                handleError,
                () => stopLoader('getInstances')
            );
        }
    }, [licenseTypeId]);

    const handleError = (err) => {
        console.log(err);
    };

    const handleGetInstanceSuccess = ({ data }) => {
        let allGroupsArray = data.data;
        allGroupsArray = sortByName(allGroupsArray);
        let processedGroups = allGroupsArray.map((device) => {
            device.hyperlink = (
                <div className="link" onClick={() => setSelectedInstance(device)}>
                    {device.name}
                </div>
            );

            return device;
        });
        const processedData = processedGroups.flat();
        setInstances(processedData);
        setSelectedInstance(processedData[0]);
    };

    let tabs = [
        {
            name: 'Details',
            component: (
                <Details
                    instances={instances}
                    selectedInstance={selectedInstance}
                    setSelectedInstance={setSelectedInstance}
                    licenseTypeId={licenseTypeId}
                    updateQueryParams={updateQueryParams}
                    queryParamsData={queryParamsData}
                />
            ),
        },
        {
            name: 'Tag',
            component: (
                <TagTabs
                    updateQueryParams={updateQueryParams}
                    queryParamsData={queryParamsData}
                />
            ),
        },
        {
            name: 'Transactions',
            component: (
                <Transactions
                    updateQueryParams={updateQueryParams}
                    queryParamsData={queryParamsData}
                    instances={instances}
                />
            ),
        },
        {
            name: 'Chargers',
            component: (
                <div className="ventilation">
                    <TabsComponent
                        tabs={[
                            {
                                name: 'All Chargers',
                                component: <AllChargers devices={instances} />,
                            },
                        ]}
                    />
                </div>
            ),
        },
    ];

    return (
        <div className={classes.Ventilation + ' ventilation'}>
            <div className={classes.Ventilation + ' ventilation'}>
                <TabsComponent
                    selectedIndex={selectedOuterIndex}
                    tabs={tabs}
                    isOpen={open}
                    setOpen={setOpen}
                    onChangeTab={(index) => {
                        setSelectedOuterIndex(index);
                        updateQueryParams('tab', index);
                    }}
                />
            </div>
        </div>
    );
};

export default EVCharging;
