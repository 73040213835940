// Standard Library Imports
import React, { useEffect } from 'react';
import { useState } from 'react';

// External Library Imports
import { toast } from 'react-toastify';
import { FieldArray, Form, Formik } from 'formik';
// Internal Module Imports
import Dropdown from '../../../components/Inputs/Dropdown';
import { useLoader } from '../../../hooks/use-loader.hook';
import { momentTimeFormater } from '../../../utils/dateHelper';
import Typography from '../../../components/Typography/Typography';
import { Input, TextArea } from '../../../components/Inputs/Input';
import { BidRequestService } from '../../../services/BidRequestService';
import { RequestBidValidation } from '../../../validations/BidRequest/RequestBidvalidation';

// CSS Imports
import ovenClasses from '../Index.module.css';
import classes from '../../../styles/BidModal.module.css';

// Asset/Image Imports
import AddIcon from '../../../assets/add.svg';
import RemoveIcon from '../../../assets/remove.svg';

const getGroupOptions = (groups) => {
    const options = [];
    groups.forEach((g) => {
        options.push({ label: g.name, value: g._id });
    });
    return options;
};

// const getGroupHashMap = groups => {
//   const hashMap = {};
//   groups.forEach(g => {
//     hashMap[g._id] = g;
//   });
//   return hashMap;
// };

// const getBiddingOptions = (groupMap, groupId) => {
//   const biddingMarkets = groupMap[groupId]?.market || [];
//   const options = [];
//   biddingMarkets.forEach(m => {
//     options.push({ label: m, value: m });
//   });
//   return options;
// };

const getInitialValues = (editRequest) => {
    const values = {
        capacity: editRequest.capacity || [],
        price: editRequest.price || [],
        groupId: editRequest.groupId || '',
        startDate: editRequest.startDate ? momentTimeFormater(editRequest.startDate).format('YYYY-MM-DD') : '',
        endDate: editRequest.endDate ? momentTimeFormater(editRequest.endDate).format('YYYY-MM-DD') : '',
        biddingMarket: editRequest.biddingMarket || '',
        excludedDays: editRequest.excludedDays ? editRequest.excludedDays.map((e) => ({ ...e, date: momentTimeFormater(e.date).format('YYYY-MM-DD') })) : [],
        note: editRequest?.note || '',
    };
    return values;
};

const RequestContent = ({ groups = [], market, setOpen, editRequest = {}, setRefresh, licenseId }) => {
    const [startLoader, stopLoader] = useLoader();
    const [initialValues, setInitialValues] = useState(getInitialValues(editRequest));
    const groupOptions = getGroupOptions(groups);
    // const groupHashMap = getGroupHashMap(groups);
    // const [biddingMarketOptions, setBiddingMarketOptions] = useState(
    //   getBiddingOptions(groupHashMap, initialValues.groupId || groupOptions[0]?.value)
    // );
    const [soltNumber, setSlotNumber] = useState(0);
    const [subSoltNumber, setSubSlotNumber] = useState(0);

    useEffect(() => {
        if (market) hanldeMarketChange(market);
    }, [market]);

    const handleSubmit = (values) => {
        values.licenseId = licenseId;
        values.biddingMarket = market;
        if (!editRequest._id) {
            BidRequestService.CreateBidRequest(values, startLoader, handleCreateSuccess, handleError, stopLoader);
        } else {
            BidRequestService.UpdateBidRequest(editRequest._id, values, startLoader, handleUpdateSuccess, handleError, stopLoader);
        }
    };

    const handleCreateSuccess = ({}) => {
        toast.success('Bid request created successfully!');
        setRefresh && setRefresh(Math.random() * 1000);
        setOpen && setOpen(false);
    };

    const handleUpdateSuccess = ({}) => {
        toast.success('Bid request updated successfully!');
        setRefresh && setRefresh(Math.random() * 1000);
        setOpen && setOpen(false);
    };

    const handleError = (err) => {
        if (err) {
            toast.error(err?.response?.data?.message);
        }
    };

    // const handleinstanceChange = ({ value }) => {
    //   const options = getBiddingOptions(groupHashMap, value);
    //   setBiddingMarketOptions(options);
    // };

    const hanldeMarketChange = (value) => {
        const capacity = Array(['FCR'].includes(value) ? 6 : ['FFR', 'FCR-D-INC', 'FCR-D-DEC'].includes(value) ? 24 : 1)
            .fill(null)
            .map((c, i) => (initialValues.capacity[i] >= 0 ? initialValues.capacity[i] : ''));
        const price = Array(['FCR'].includes(value) ? 6 : ['FFR', 'FCR-D-INC', 'FCR-D-DEC'].includes(value) ? 24 : 1)
            .fill(null)
            .map((c, i) => (initialValues.price[i] >= 0 ? initialValues.price[i] : ''));
        // setFieldValue('capacity', capacity);
        // setFieldValue('price', price);
        setInitialValues({
            ...initialValues,
            capacity,
            price,
        });
        if (['FCR'].includes(value)) {
            setSlotNumber(2);
            setSubSlotNumber(3);
        } else if (['FFR', 'FCR-D-INC', 'FCR-D-DEC'].includes(value)) {
            setSlotNumber(6);
            setSubSlotNumber(4);
        } else {
            setSlotNumber(0);
            setSubSlotNumber(0);
        }
    };

    return (
        <div>
            <Typography content={`${editRequest._id ? 'Edit' : 'Create'} Request`} />
            <div className={classes.FormContainer}>
                <Formik initialValues={initialValues} onSubmit={handleSubmit} validationSchema={RequestBidValidation} enableReinitialize>
                    {({ errors, touched, values, isValidating, ...props }) => (
                        <Form>
                            <div>
                                <div>
                                    <div className={classes.Subtitle}>Config</div>
                                    <div className={classes.InputContainer}>
                                        <div className={classes.FieldControl} style={{ flexGrow: 1 }}>
                                            <label for="groupId">
                                                Instance <span className="required">*</span>
                                            </label>
                                            <Dropdown
                                                name="groupId"
                                                options={groupOptions}
                                                defaultValue={initialValues.groupId ? groupOptions.filter((group) => group.value === values.groupId)[0] : null}
                                            />
                                        </div>
                                        {/*   <div className={classes.FieldControl} style={{ flexGrow: 1 }}>
                      <label for="groupId">
                        Bidding Market <span className="required">*</span>
                      </label>
                      <Dropdown
                        name="biddingMarket"
                        options={biddingMarketOptions}
                        defaultValue={
                          initialValues.biddingMarket
                            ? biddingMarketOptions.filter(group => group.value === values.biddingMarket)[0]
                            : biddingMarketOptions[0]
                        }
                        onChange={e => hanldeMarketChange(e, props.setFieldValue)}
                      />
                    </div> */}
                                    </div>
                                    <div className={classes.InputContainer}>
                                        <div className={classes.FieldControl} style={{ flexGrow: 1 }}>
                                            <label>
                                                Start Date <span className="required">*</span>
                                            </label>
                                            <div className="modal-date-picker">
                                                <Input name="startDate" id="stratdate" type="date" />
                                            </div>
                                        </div>
                                        <div className={classes.FieldControl} style={{ flexGrow: 1 }}>
                                            <label>
                                                End Date <span className="required">*</span>
                                            </label>
                                            <div className="modal-date-picker">
                                                <Input name="endDate" id="enddate" type="date" />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className={classes.Subtitle}>Capacity(Mw)</div>
                                {!soltNumber && <div style={{ textAlign: 'center' }}>Selected market is not supported for requests!</div>}
                                {
                                    <>
                                        {Array(soltNumber)
                                            .fill(null)
                                            .map((item, index) => (
                                                <div className={classes.InputContainer}>
                                                    {Array(subSoltNumber)
                                                        .fill(null)
                                                        .map((subItem, subIndex) => (
                                                            <div>
                                                                <div className={classes.FieldControl}>
                                                                    <label for="slot1">
                                                                        Slot {index * subSoltNumber + subIndex + 1} / Hour{' '}
                                                                        {soltNumber === 2
                                                                            ? `${(index * subSoltNumber + subIndex) * 4}-${(index * subSoltNumber + subIndex + 1) * 4}`
                                                                            : `${index * subSoltNumber + subIndex}-${index * subSoltNumber + subIndex + 1}`}
                                                                        <span className="required">*</span>
                                                                    </label>
                                                                    <Input name={`capacity.${index * subSoltNumber + subIndex}`} id="slot1" />
                                                                </div>
                                                            </div>
                                                        ))}
                                                </div>
                                            ))}
                                    </>
                                }
                            </div>
                            <div>
                                <div className={classes.Subtitle}>Price(€)</div>
                                {!soltNumber && <div style={{ textAlign: 'center' }}>Selected market is not supported for requests!</div>}
                                {
                                    <>
                                        {Array(soltNumber)
                                            .fill(null)
                                            .map((item, index) => (
                                                <div className={classes.InputContainer}>
                                                    {Array(subSoltNumber)
                                                        .fill(null)
                                                        .map((subItem, subIndex) => (
                                                            <div>
                                                                <div className={classes.FieldControl}>
                                                                    <label for="slot1">
                                                                        Slot {index * subSoltNumber + subIndex + 1} / Hour{' '}
                                                                        {soltNumber === 2
                                                                            ? `${(index * subSoltNumber + subIndex) * 4}-${(index * subSoltNumber + subIndex + 1) * 4}`
                                                                            : `${index * subSoltNumber + subIndex}-${index * subSoltNumber + subIndex + 1}`}
                                                                        <span className="required">*</span>
                                                                    </label>
                                                                    <Input name={`price.${index * subSoltNumber + subIndex}`} id="slot1" />
                                                                </div>
                                                            </div>
                                                        ))}
                                                </div>
                                            ))}
                                    </>
                                }
                            </div>
                            <div className={ovenClasses.FieldControl}>
                                <label for="note">
                                    Note <span className="required">*</span>
                                </label>
                                <TextArea
                                    name={`note`}
                                    label="Note"
                                    setFieldValue={props.setFieldValue}
                                    setFieldTouched={props.setFieldTouched}
                                    getFieldMeta={props.getFieldMeta}
                                />
                            </div>
                            <div>
                                {values.excludedDays.length ? (
                                    <>
                                        <div className={classes.Subtitle}>Excluded Days</div>
                                        <FieldArray name="excludedDays">
                                            {({ push, remove, form }) => {
                                                const { excludedDays } = form.values;
                                                return (
                                                    <div>
                                                        <div>
                                                            {excludedDays.map((e, index) => (
                                                                <div className={ovenClasses.InputContainer}>
                                                                    <div className={ovenClasses.FieldControl2}>
                                                                        <label for="groupId">
                                                                            Date <span className="required">*</span>
                                                                        </label>
                                                                        <Input name={`excludedDays[${index}].date`} type="date" />
                                                                    </div>
                                                                    <div className={ovenClasses.FieldControl}>
                                                                        <label for="groupId">
                                                                            Reason <span className="required">*</span>
                                                                        </label>
                                                                        <TextArea
                                                                            name={`excludedDays[${index}].reason`}
                                                                            label="Reason"
                                                                            setFieldValue={props.setFieldValue}
                                                                            setFieldTouched={props.setFieldTouched}
                                                                            getFieldMeta={props.getFieldMeta}
                                                                        />
                                                                    </div>
                                                                </div>
                                                            ))}
                                                        </div>
                                                        <div className={ovenClasses.AddInputActionWrapper}>
                                                            {excludedDays.length > 0 && (
                                                                <span onClick={() => remove(excludedDays.length - 1)}>
                                                                    <img src={RemoveIcon} className={classes.addInputActionbutton} />
                                                                </span>
                                                            )}
                                                            <span onClick={() => push({ date: '', reason: '' })}>
                                                                <img src={AddIcon} className={classes.addInputActionbutton} />
                                                            </span>
                                                        </div>
                                                    </div>
                                                );
                                            }}
                                        </FieldArray>
                                    </>
                                ) : (
                                    <>
                                        <div>
                                            <button type="submit" className="btn-primary" onClick={() => values.excludedDays.push({ date: '', reason: '' })}>
                                                Add Excluded Days
                                            </button>
                                        </div>
                                    </>
                                )}
                            </div>
                            <div className={classes.ButtonContainer}>
                                <div>
                                    <button type="button" className="btn-secondary" onClick={() => setOpen(false)}>
                                        Cancel
                                    </button>
                                </div>
                                <div>
                                    <button type="submit" className="btn-primary">
                                        Submit
                                    </button>
                                </div>
                            </div>
                        </Form>
                    )}
                </Formik>
            </div>
        </div>
    );
};

export default RequestContent;
