// External library imports
import moment from 'moment';
// Internal module imports
import { HttpClient } from '../utils/httpClient';

const PATH = {
    getData: '/client/data/frequency/',
    event: 'client/data/event',
    eventLicense: 'client/data/event/license',
    powercapacity: 'client/data/powercapacity',
    fcrBids: 'client/data/fcr',
    checkbid: 'client/data/checkbid',
    frequencyLastReported: 'client/data/frequency/data/last-reported',
    capacityLastReported: 'client/data/powercapacity/last-reported',
    marketEnabledGroups: 'client/data/powercapacity/groups-v2',
};

const getFrequencyData = (frequency, params, start, callback, error, next) => {
    start();
    return HttpClient.get(`${PATH.getData + frequency}`, { params })
        .then(callback)
        .catch(error)
        .finally(next);
};

export const getAllEvents = (filter, dateRange, start, callback, error, next) => {
    start();
    let params = {
        startDate: moment(dateRange.startDate).format('YYYY-MM-DD'),
        endDate: moment(dateRange.endDate).format('YYYY-MM-DD'),
        ...filter,
    };
    return HttpClient.get(`${PATH.event}`, { params }).then(callback).catch(error).finally(next);
};

export const getDataLog = (id, params, start, callback, error, next) => {
    start();
    return HttpClient.get(`${PATH.event}/${id}/data-log`, { params }).then(callback).catch(error).finally(next);
};

export const getAllLicenseEvents = (filter, date, start, callback, error, next) => {
    start();
    let params = {
        date: moment(date).format('YYYY-MM-DD'),
        ...filter,
    };
    return HttpClient.get(`${PATH.eventLicense}`, { params }).then(callback).catch(error).finally(next);
};

export const getAllLicenseActivationEvents = (filter, date, start, callback, error, next) => {
    start();
    let params = {
        date: moment(date).format('YYYY-MM-DD'),
        ...filter,
    };
    return HttpClient.get(`${PATH.eventLicense}/activation`, { params }).then(callback).catch(error).finally(next);
};

export const getAllLicenseMetaEvents = (filter, date, start, callback, error, next) => {
    start();
    let params = {
        date: moment(date).format('YYYY-MM-DD'),
        ...filter,
    };
    return HttpClient.get(`${PATH.eventLicense}/meta`, { params }).then(callback).catch(error).finally(next);
};

const exportEvent = (id, start, callback, error, next) => {
    start();
    return HttpClient.get(`${PATH.event}/${id}/export`).then(callback).catch(error).finally(next);
};

const getPowerCapacity = (params, start, callback, error, next) => {
    start();
    return HttpClient.get(`${PATH.powercapacity}`, { params }).then(callback).catch(error).finally(next);
};

const getBids = (params, start, callback, error, next) => {
    start();
    return HttpClient.get(`${PATH.fcrBids}`, { params }).then(callback).catch(error).finally(next);
};

const createBid = (params, values, start, callback, error, next) => {
    start();
    return HttpClient.post(`${PATH.fcrBids}`, values, { params }).then(callback).catch(error).finally(next);
};

const deleteBid = (id, start, callback, error, next) => {
    start();
    return HttpClient.delete(`${PATH.fcrBids}`, { params: { _id: id } })
        .then(callback)
        .catch(error)
        .finally(next);
};

const getLastBid = (params, start, callback, error, next) => {
    start();
    return HttpClient.get(`${PATH.fcrBids}/last`, { params }).then(callback).catch(error).finally(next);
};

// const getStatus = (params, start, callback, error, next) => {
//   start();
//   return HttpClient.patch(`${PATH.checkbid}`, {}, { params }).then(callback).catch(error).finally(next);
// };

// const postToExternalBid = (params, id, payload, start, callback, error, next) => {
//   start();
//   return HttpClient.post(`${PATH.fcrBids}/createBid/${id}`, { bid: payload }, { params })
//     .then(callback)
//     .catch(error)
//     .finally(next);
// };

const frequencyLastReported = (params, start, callback, error, next) => {
    start();
    return HttpClient.get(`${PATH.frequencyLastReported}`, { params }).then(callback).catch(error).finally(next);
};

const capacityLastReported = (start, callback, error, next, market = 'FCR', instanceId) => {
    const params = { market, instanceId };
    start();
    return HttpClient.get(`${PATH.capacityLastReported}`, { params }).then(callback).catch(error).finally(next);
};

const getMarketEnabledGroups = (market, start, callback, error, next) => {
    start();
    return HttpClient.get(`${PATH.marketEnabledGroups}/${market}`).then(callback).catch(error).finally(next);
};

const autoRefeshFrequencyLastReported = (params, callback, error, next) => {
    return HttpClient.get(`${PATH.frequencyLastReported}`, { params }).then(callback).catch(error).finally(next);
};

const autoRefreshGetFrequencyData = (frequency, params, callback, error, next) => {
    return HttpClient.get(`${PATH.getData + frequency}`, { params })
        .then(callback)
        .catch(error)
        .finally(next);
};

export const FrequencyService = {
    getFrequencyData,
    getAllEvents,
    exportEvent,
    getDataLog,
    getPowerCapacity,
    getBids,
    createBid,
    deleteBid,
    getLastBid,
    // postToExternalBid,
    // getStatus,
    getAllLicenseEvents,
    frequencyLastReported,
    capacityLastReported,
    getAllLicenseActivationEvents,
    getAllLicenseMetaEvents,
    getMarketEnabledGroups,
    autoRefeshFrequencyLastReported,
    autoRefreshGetFrequencyData,
};
