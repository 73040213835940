import { HttpClient } from '../utils/httpClient';

const PATH = {
    getData: '/client/data/power-cost',
    updateTax: '/client/customer/update-tax',
    getPricing: '/client/data/pricing',
    realtimeMarket: '/client/data/realtime-market',
    lastReported: '/client/data/demand-response/last-reported',
    getPowerCostExcelData: '/client/data/power-cost-export',
    getPricesBulkExcelData: '/client/data/prices/bulk-download',
};

const getData = (date, start, callback, error, next) => {
    start();
    return HttpClient.get(`${PATH.getData}`, { params: { date } }).then(callback).catch(error).finally(next);
};

const getPowerCostExcelData = (params, start, callback, error, next) => {
    start();
    return HttpClient.get(`${PATH.getPowerCostExcelData}`, { params }).then(callback).catch(error).finally(next);
};

const getPricing = (type, priceType, date, start, callback, error, next) => {
    start();
    return HttpClient.get(`${PATH.getPricing}/${type}`, { params: { date, priceType } }).then(callback).catch(error).finally(next);
};

const realtimeMarket = (priceSignal, date, start, callback, error, next) => {
    start();
    return HttpClient.get(`${PATH.realtimeMarket}`, { params: { date, priceSignal } }).then(callback).catch(error).finally(next);
};

const updateTax = (values, start, callback, error, next) => {
    start();
    return HttpClient.patch(`${PATH.updateTax}`, values).then(callback).catch(error).finally(next);
};
const lastReported = (filteredParams, start, callback, error, next) => {
    start();
    return HttpClient.get(`${PATH.lastReported}`, { params: { ...filteredParams } })
        .then(callback)
        .catch(error)
        .finally(next);
};

const downloadBulkData = (params, start, callback, error, next) => {
    start();
    return HttpClient.get(`${PATH.getPricesBulkExcelData}`, { params }).then(callback).catch(error).finally(next);
};

export const NordpoolService = {
    getData,
    updateTax,
    getPricing,
    realtimeMarket,
    lastReported,
    getPowerCostExcelData,
    downloadBulkData
};
