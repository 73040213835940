// Standard library imports
import React, { useState, useEffect } from 'react';

// Internal module imports
import ChartComponent from '../../../components/ApexCharts/Chart';
import { timeOnly } from '../../../utils/dateHelper';
import { useLoader } from '../../../hooks';

// Css imports
import classes from '../../DemandResponse/DemandResponse.module.css';
import { EVCService } from '../../../services/EVCService';
import TabsComponent from '../../../components/Tabs/Tabs';

const TransactionInfoModal = ({ data, setOpen, updateQueryParams, queryParamsData }) => {
    const [startLoader, stopLoader] = useLoader();
    const [graphData, setGraphData] = useState([]);
    const [consumptionData, setConsumptionData] = useState([]);
    const [socData, setSocData] = useState([]);
    const [selectedIndex] = useState(queryParamsData.subTab ? queryParamsData.subTab : 0);

    useEffect(() => {
        fetchTransactionInfo();
    }, []);

    const fetchTransactionInfo = () => {
        if (data._id) {
            EVCService.FetchTransactionInfo(data._id, startLoader, handleTransactionSuccess, handleError, stopLoader);
        }
    };

    const handleTransactionSuccess = ({ data }) => {
        setGraphData(data.data.powerData);
        setConsumptionData(data.data.consumptionData || [{ data: [] }]);
        setSocData(data.data.SocData || [{ data: [] }]);
    };

    const handleError = (err) => {
        console.log(err);
    };

    let tabs = [
        {
            name: 'Power (kW)',
            component: (
                <div>
                    <ChartComponent type="line" series={graphData} xFormatter={timeOnly} height="180%" />
                </div>
            ),
        },
        {
            name: 'Consumption (kWh)',
            component: (
                <div>
                    <ChartComponent type="area" series={consumptionData} xFormatter={timeOnly} height="180%" />
                </div>
            ),
        },
        {
            name: 'SoC (kWh)',
            component: (
                <div>
                    <ChartComponent type="line" series={socData} xFormatter={timeOnly} height="180%" />
                </div>
            ),
        },
    ];


    return (
        <div style={{ width: '50vw', maxHeight: '90vh' }}>
            <TabsComponent
                tabs={tabs}
                selectedIndex={selectedIndex}
                onChangeTab={(index) => {
                    updateQueryParams('subTab', index);
                }}
            />
            <div className={classes.ButtonContainer}>
                <div className="btn-secondary" onClick={setOpen}>
                    Close
                </div>
            </div>
        </div>
    );
};

export default TransactionInfoModal;
