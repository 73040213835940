// Standard library imports
import React, { useContext, useEffect, useState } from 'react';

// External library imports
import { useLocation, useHistory } from 'react-router-dom';

// Internal module imports
import TabsComponent from '../../components/Tabs/Tabs';
import Details from './Details/Details';
import Events from './Events/Events';
import Alerts from './Alerts/Alerts';
import { LicenseService } from '../../services/LicenseService';
import { AuthContext } from '../../context/AuthContext';
import { LoaderContext } from '../../context/LoaderContext';
import { START_LOADER, STOP_LOADER, USER_ROLE } from '../../constants';
import DeleteIcon from '../../assets/delete.png';
import EditIcon from '../../assets/edit.png';
import AllInstances from './AllInstances/AllInstances';
import DeleteModalComponent from '../../components/DeleteModal/DeleteModal';
import { get as LodashGet } from 'lodash';
import { sortByName } from '../../utils/objectHelper';
import CustomTooltip from '../../components/CustomToolTip/CustomTooltip';
import InfoIcon from '../../assets/info.svg';

// Css module imports
import classes from './E3App.module.css';

const getFrequencySignalMap = (customer) => {
    const { frequency = [] } = customer;
    const map = [];
    frequency.forEach((f) => (map[f._id] = f.name));
    return map;
};

const E3App = () => {
    const location = useLocation();
    const history = useHistory();
    const queryParams = new URLSearchParams(location.search);

    const { state } = useContext(AuthContext);
    const userRole = LodashGet(state, 'user.userRole');
    const licenseTypeId = state.isAuthorized ? queryParams.get('licenseTypeId') : '';
    const enabledLicense = LodashGet(state, `user.customerId.enabledLicense.${licenseTypeId}`);
    const customer = LodashGet(state, 'user.customerId');
    // const licenseId = state.isAuthorized ? LodashGet(state, 'user.customerId.license.fan.id') : '';
    const settings = enabledLicense?.settings;
    const tabSettings = {
        overview: settings?.overview ? settings.overview : false,
        alerts: settings?.alerts ? settings.alerts : false,
        events: settings?.events ? settings.events : false,
        details: settings?.details ? settings.details : false,
    };

    const { dispatch: loaderDispatch } = useContext(LoaderContext);
    const startLoader = (payload) => loaderDispatch({ type: START_LOADER, payload });
    const stopLoader = (payload) => loaderDispatch({ type: STOP_LOADER, payload });

    const [queryParamsData] = useState({
        subTab: queryParams.get('subTab') ? Number(queryParams.get('subTab')) : '',
        tab: queryParams.get('tab') ? Number(queryParams.get('tab')) : '',
        limit: queryParams.get('limit') ? Number(queryParams.get('limit')) : '',
        date: queryParams.get('date') ? new Date(queryParams.get('date')) : '',
        createAlertModal: queryParams.get('createAlertModal') && queryParams.get('createAlertModal') === 'true' ? Boolean(queryParams.get('createAlertModal')) : false,
    });
    const [open, setOpen] = useState(false);
    const [licenseDetails, setLicenseDetails] = useState({});
    const [deleteInstanceModal, setDeleteInstanceModal] = useState({ status: false, id: '' });
    const [groups, setGroups] = useState([]);
    const [device, setDevice] = useState({
        name: '',
        _id: '',
    });

    const [refresh, setRefresh] = useState({});
    const [customerFrequencyMap] = useState(getFrequencySignalMap(customer));
    const [selectedIndex, setSelectedIndex] = useState(queryParamsData.subTab ? queryParamsData.subTab : 0);
    const [selectedOuterIndex, setSelectedOuterIndex] = useState(queryParamsData.tab ? queryParamsData.tab : 0);
    const [licenseTypeHash, setLicenseTypeHash] = useState({});

    useEffect(() => {
        if (licenseTypeHash[licenseTypeId]) {
            const licenseDetail = licenseTypeHash[licenseTypeId];
            setLicenseDetails({
                _id: licenseDetail._id,
                type: licenseDetail.name,
                groups,
            });
        }
    }, [licenseTypeHash, groups]);

    useEffect(() => {
        if (licenseTypeId) {
            fetchLicenseTypes();
            LicenseService.getLicenseTypeDetails(
                customer._id,
                licenseTypeId,
                () => startLoader('getInstances'),
                handleGetInstanceSuccess,
                handleError,
                () => stopLoader('getInstances')
            );
        }
    }, [licenseTypeId, refresh]);

    const fetchLicenseTypes = () => {
        LicenseService.getLicenseTypes(
            () => startLoader('getInstances'),
            handleLicenseTypesSuccess,
            handleError,
            () => stopLoader('getInstances')
        );
    };

    const handleLicenseTypesSuccess = ({ data }) => {
        const licenseTypesHash = {};
        data?.data.forEach((type) => {
            licenseTypesHash[type._id] = type;
        });
        setLicenseTypeHash(licenseTypesHash);
    };

    const updateQueryParams = (type, value) => {
        let queryParamsNew = queryParams;
        if (type === 'tab') {
            const licenseTypeId = queryParamsNew.get('licenseTypeId');
            queryParamsNew = new URLSearchParams();
            queryParamsNew.set('licenseTypeId', licenseTypeId);
        }
        queryParamsNew.set(type, value);
        history.push({ search: queryParamsNew.toString() });
    };

    const handleGetInstanceSuccess = ({ data }) => {
        let allGroupsArray = data.data;
        allGroupsArray = sortByName(allGroupsArray);
        let processedGroups = allGroupsArray.map((device) => {
            device.hyperlink = (
                <div className="link" onClick={() => setDevice(device)}>
                    {device.name}
                </div>
            );
            device.populatedFrequency = (
                <div>
                    {customerFrequencyMap[device.properties?.frequencySignal] ? (
                        customerFrequencyMap[device.properties?.frequencySignal]
                    ) : (
                        <CustomTooltip content={`Frequency Signal with id "${device.properties?.frequencySignal}" is not enabled`}>
                            <div style={{ display: 'flex', gridColumnGap: '1vw', justifyContent: 'center' }}>
                                <img className={classes.ActionIcon} src={InfoIcon} alt="" />
                            </div>
                        </CustomTooltip>
                    )}
                </div>
            );

            return device;
        });

        setGroups(processedGroups.flat());
    };

    const handleError = (err) => {
        console.log(err);
    };

    const handleDeleteInstance = (id) => {
        LicenseService.deleteGroup(
            id,
            () => startLoader('deleteInstance'),
            ({ data }) => handleDeleteSuccess(data, id),
            handleError,
            () => stopLoader('deleteInstance')
        );
    };

    const handleDeleteSuccess = (data, id) => {
        setRefresh(Math.random() * 1000);
    };

    let tabs = [
        ...(tabSettings.overview
            ? [
                {
                    name: 'Overview',
                    component: (
                        <Details
                            device={device}
                            setDevice={setDevice}
                            setRefresh={setRefresh}
                            license={groups}
                            startLoader={startLoader}
                            stopLoader={stopLoader}
                            updateQueryParams={updateQueryParams}
                            queryParamsData={queryParamsData}
                        />
                    ),
                },
            ]
            : []),

        ...(tabSettings.alerts
            ? [
                {
                    name: 'Alerts',
                    component: (
                        <Alerts
                            license={licenseDetails}
                            startLoader={startLoader}
                            stopLoader={stopLoader}
                            updateQueryParams={updateQueryParams}
                            queryParamsData={queryParamsData}
                        />
                    ),
                },
            ]
            : []),
        // ...(tabSettings.events
        //     ? [
        //           {
        //               name: 'Events',
        //               component: (
        //                   <Events
        //                       license={licenseDetails}
        //                       source={'fan'}
        //                       startLoader={startLoader}
        //                       stopLoader={stopLoader}
        //                       updateQueryParams={updateQueryParams}
        //                       queryParamsData={queryParamsData}
        //                   />
        //               ),
        //           },
        //       ]
        //     : []),
        ...(tabSettings.details
            ? [
                {
                    name: 'Details',
                    component: (
                        <div className="ventilation">
                            <TabsComponent
                                onChangeTab={(index) => {
                                    setSelectedIndex(index);
                                    updateQueryParams('subTab', index);
                                }}
                                selectedIndex={selectedIndex}
                                tabs={[
                                    {
                                        name: 'All Instances',
                                        component: <AllInstances devices={groups} />,
                                    },
                                ]}
                            />
                        </div>
                    ),
                },
            ]
            : []),
    ];

    return (
        <div className={classes.Ventilation + ' ventilation'}>
            <DeleteModalComponent
                deletefunction={handleDeleteInstance}
                opendeleteModal={deleteInstanceModal.status}
                setOpenDeleteModal={(status) => setDeleteInstanceModal({ id: '', status })}
                deviceId={deleteInstanceModal.id}
            />
            <div className={classes.Ventilation + ' ventilation'}>
                <TabsComponent
                    selectedIndex={selectedOuterIndex}
                    // resetIndex={device.name}
                    tabs={tabs}
                    isOpen={open}
                    setOpen={setOpen}
                    onChangeTab={(index) => {
                        setSelectedOuterIndex(index);
                        updateQueryParams('tab', index);
                    }}
                />
            </div>
        </div>
    );
};

export default E3App;
