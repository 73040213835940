// Standard library imports
import React, { useContext, useState, useEffect } from 'react';

// External library imports
import { Form, Formik } from 'formik';
import { toast } from 'react-toastify';
import { UilTrash, UilPen } from '@iconscout/react-unicons';

// Internal module imports
import Table from '../../../components/Table/Table';
import ModalComponent from '../../../components/ModalComponent/ModalComponent';
import Typography from '../../../components/Typography/Typography';
import { DropdownComponent, Input, ToggleButton, ToggleButtonWithState } from '../../../components/Inputs/Input';
import { USER_ROLE } from '../../../constants';
import { AuthContext } from '../../../context/AuthContext';
import { get as LodashGet } from 'lodash';
import CreateButton from '../../../components/Buttons/CreateButton';
import { EVCService } from '../../../services/EVCService';
import { LoaderContext } from '../../../context/LoaderContext';
import { START_LOADER, STOP_LOADER } from '../../../constants';
import { CreateTagValidation } from '../../../validations/EVCValidation/TagValidation';
import DeleteModalComponent from '../../../components/DeleteModal/DeleteModal';
import CustomTooltip from '../../../components/CustomToolTip/CustomTooltip';
import Dropdown from '../../../components/Inputs/Dropdown';

// Css module imports
import classes from '../../../styles/Alerts.module.css';
import buttonClasses from '../../../components/Buttons/Button.module.css';
import myClasses from '../../../styles/AllDevices.module.css';
import evcClasses from '../EVCharging.module.css';

const LIMIT = 15;

const initialValues = {
    name: '',
    tagID: '',
    action: false,
    groupId: '',
    properties: {
        chargingType: 'tagId'
    }
};

const defaultValue = {
    label: 'All',
    value: 'All'
};

const Tags = ({ updateQueryParams, queryParamsData }) => {

    const { dispatch: loaderDispatch } = useContext(LoaderContext);
    const startLoader = (payload) => loaderDispatch({ type: START_LOADER, payload });
    const stopLoader = (payload) => loaderDispatch({ type: STOP_LOADER, payload });

    const { state } = useContext(AuthContext);
    const userRole = LodashGet(state, 'user.userRole');
    const [open, setOpen] = useState(queryParamsData.createTagModal ? queryParamsData.createTagModal : false);
    const [tableData, setTableData] = useState([]);
    const [deleteModal, setDeleteModal] = useState({ status: false, id: true });
    const [groups, setGroups] = useState([]);
    const [filterGroups, setFilterGroups] = useState([defaultValue]);
    const [selectedGroup, setSelectedGroup] = useState(defaultValue);
    const [skip, setSkip] = useState(0);
    const [page, setPage] = useState(0);
    const [totalCount, setTotalCount] = useState(0);
    const [tagFormValue, setTagFormValue] = useState(initialValues);

    const chargingTypeOptions = [
        { label: 'Tag ID', value: 'tagId' }
    ];

    useEffect(() => {
        getAllTags();
    }, [skip, selectedGroup?.value]);

    useEffect(() => {
        getAllGroups();
    }, [])

    useEffect(() => {
        updateQueryParams('createTagModal', open);
    }, [open])

    const getAllGroups = () => {
        EVCService.GetAllGroups(
            () => startLoader('getGroups'),
            handleGetGroupsSuccess,
            handleError,
            () => stopLoader('getGroups')
        );
    };

    const handleGetGroupsSuccess = ({ data }) => {
        const processedData = data.data.map((item) => {
            return {
                label: item.name,
                value: item._id,
            }
        });

        setGroups(processedData);
        setFilterGroups([defaultValue, ...processedData]);
    }

    const handleSubmit = (data) => {
        const formData = {
            name: data.name,
            tagID: data.tagID,
            action: data.action,
            ...(data?.groupId ? { groupId: data?.groupId } : {}),
            properties: {
                chargingType: data.properties.chargingType
            }
        }

        if (data._id) {
            EVCService.UpdateTag(
                data._id,
                formData,
                () => startLoader('updateTag'),
                () => handleCreateSuccess('Updated'),
                handleError,
                () => stopLoader('updateTag')
            );
        } else {
            EVCService.CreateTag(
                formData,
                () => startLoader('createTag'),
                () => handleCreateSuccess('Created'),
                handleError,
                () => stopLoader('createTag')
            );
        }
    }

    const handleError = (err) => {
        let data = err && err.response ? err.response.data : null;
        if (data) toast.error(data.message);
        else toast.error('Internal server error!');
    };

    const handleCreateSuccess = (type) => {
        getAllTags();
        setOpen(false);
        toast.success(`Tag ${type} Successfully`);
    };

    const getAllTags = () => {
        EVCService.GetAllTags(
            {
                limit: LIMIT,
                skip,
                groupId: selectedGroup.value
            },
            () => startLoader('getTags'),
            handleGetTagsSuccess,
            handleError,
            () => stopLoader('getTags')
        );
    };

    const handleTagToggleChange = (active, item) => {
        item.action = active;
        handleSubmit(item)
    }

    const getActive = (item) => {
        return (
            <div className={classes.ActionIcons}>
                <ToggleButtonWithState
                    value={item.action}
                    onChange={(e) => handleTagToggleChange(e.target.checked, item)}
                    warpperStyle={{ marginTop: '0' }}
                />
            </div>
        )
    };

    const getActions = (item) => {
        return (
            <div className={classes.ActionIcons}>
                <div onClick={() => {
                    setOpen(true);
                    setTagFormValue(item);
                }}>
                    <CustomTooltip content={'Edit'}>
                        <UilPen className={buttonClasses.ButtonIcon} />
                    </CustomTooltip>
                </div>
                <div onClick={() => setDeleteModal({ status: true, id: item._id })}>
                    <CustomTooltip content={'Delete'}>
                        <UilTrash className={buttonClasses.ButtonIcon} />
                    </CustomTooltip>
                </div>
            </div>
        );
    };

    const handleGetTagsSuccess = ({ data }) => {
        const { tags, totalCount } = data?.data[0];
        const processedData = tags?.map((item) => {
            return {
                ...item,
                group: item.groupData[0]?.name ? item.groupData[0].name : '--',
                active: getActive(item),
                action: getActions(item)
            }
        });

        setTableData(processedData);
        setTotalCount(totalCount?.length ? totalCount[0].totalCount : 0);
    }


    const handleDeleteSuccess = (data, id) => {
        toast.success('Tag Deleted!');
        getAllTags();
    };

    const handleDelete = (id) => {
        EVCService.DeleteTag(
            id,
            () => startLoader('deleteTag'),
            handleDeleteSuccess,
            handleError,
            () => stopLoader('deleteTag')
        );
    };

    const handlePageChange = (pageno) => {
        setSkip(LIMIT * pageno);
        setPage(pageno);
    };

    return (
        <div className={classes.Alert}>
            <div className={classes.TableHeadSection} >
                <div className={evcClasses.HeadingContainer}>
                    <Typography content="Tags" />
                    <div className={myClasses.TableCount}>
                        Total Count :
                        <span>
                            <Typography size="14" content={tableData.length} />
                        </span>
                    </div>
                    <div className={evcClasses.DropDnContainer} >
                        <DropdownComponent
                            name="groupId"
                            options={filterGroups}
                            defaultValue={filterGroups.length ? filterGroups[0] : {}}
                            onChange={(e) => setSelectedGroup(e)}
                        />
                    </div>
                </div>
                {[USER_ROLE.SUDOER, USER_ROLE.EXPERT].includes(userRole) ? (
                    <div className={classes.CreateButton}>
                        <CreateButton
                            size="medium"
                            text="Create Tag"
                            onClick={() => {
                                setTagFormValue(initialValues);
                                setOpen(!open);
                            }}
                        />
                    </div>
                ) : null}
            </div>

            <Table
                head={[
                    'Name',
                    'Tag Id',
                    'Group',
                    [USER_ROLE.SUDOER, USER_ROLE.EXPERT].includes(userRole) ? 'Active' : '',
                    [USER_ROLE.SUDOER, USER_ROLE.EXPERT].includes(userRole) ? 'Action' : ''
                ]}
                keys={[
                    'name',
                    'tagID',
                    'group',
                    [USER_ROLE.SUDOER, USER_ROLE.EXPERT].includes(userRole) ? 'active' : '',
                    [USER_ROLE.SUDOER, USER_ROLE.EXPERT].includes(userRole) ? 'action' : ''
                ]}
                data={tableData}
                page={page}
                Pagination={true}
                limit={LIMIT}
                handlePageChange={handlePageChange}
                totalCount={totalCount}
            />

            <ModalComponent isOpen={open} setOpen={setOpen} onClose={() => setOpen(false)}>
                <div className={classes.ModalWidth}>
                    <Formik initialValues={tagFormValue} validationSchema={CreateTagValidation} onSubmit={(val, { resetForm }) => { handleSubmit(val, resetForm); }}>
                        {({ errors, touched, values, isValidating, ...props }) => {
                            return (
                                <Form>
                                    <Typography content="Create Tag" />
                                    <div>
                                        <div className={classes.InputContainer}>
                                            <div className={classes.FieldControl2 + ' ' + classes.FieldControlGrow}>
                                                <label for="name">
                                                    Name <span className="required">*</span>
                                                </label>
                                                <Input name="name" id="name" />
                                            </div>

                                            <div className={classes.FieldControl2 + ' ' + classes.FieldControlGrow}>
                                                <label for="tagID">
                                                    Tag ID <span className="required">*</span>
                                                </label>
                                                <div>
                                                    <Input name="tagID" id="tagID" />
                                                </div>
                                            </div>
                                        </div>
                                        <div className={classes.InputContainer}>
                                            <div className={classes.FieldControl2 + ' ' + classes.FieldControlGrow}>
                                                <label for="groupId">
                                                    Group
                                                </label>
                                                <Dropdown
                                                    name="groupId"
                                                    options={groups}
                                                    defaultValue={values?.groupId && groups.filter((group) => group.value === values?.groupId).length && groups.filter((group) => group.value === values?.groupId)[0]}
                                                />
                                            </div>

                                            <div className={classes.FieldControl2 + ' ' + classes.FieldControlGrow}>
                                                <ToggleButton label={'Active'} values={values} name="action" id="action" />
                                            </div>
                                        </div>
                                        <div className={classes.InputContainer}>
                                            <div className={classes.FieldControl2 + ' ' + classes.FieldControlGrow}>
                                                <label for="chargingType">
                                                    Charging Type <span className="required">*</span>
                                                </label>
                                                <Dropdown
                                                    name="properties.chargingType"
                                                    options={chargingTypeOptions}
                                                    defaultValue={chargingTypeOptions[0]}
                                                />
                                            </div>
                                        </div>

                                        <div className={classes.ButtonContainer}>
                                            <div>
                                                <div
                                                    className="btn-secondary"
                                                    onClick={() => {
                                                        setOpen(false);
                                                    }}
                                                >
                                                    Cancel
                                                </div>
                                            </div>
                                            <div>
                                                <button type="submit" className="btn-primary">
                                                    Submit
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                </Form>
                            );
                        }}
                    </Formik>
                </div>
            </ModalComponent>

            <DeleteModalComponent
                deletefunction={handleDelete}
                opendeleteModal={deleteModal.status}
                setOpenDeleteModal={(status) => setDeleteModal({ id: '', status })}
                deviceId={deleteModal.id}
            ></DeleteModalComponent>
        </div>
    );
};

export default Tags;
