// Standard library imports
import React, { useContext, useState, useEffect } from 'react';

// Internal module imports
import Table from '../../../components/Table/Table';
import ModalComponent from '../../../components/ModalComponent/ModalComponent';
import { Form, Formik } from 'formik';
import Typography from '../../../components/Typography/Typography';
import { Input, ToggleButton, ToggleButtonWithState } from '../../../components/Inputs/Input';
import { USER_ROLE } from '../../../constants';
import { AuthContext } from '../../../context/AuthContext';
import { get as LodashGet } from 'lodash';
import CreateButton from '../../../components/Buttons/CreateButton';
import { EVCService } from '../../../services/EVCService';
import { LoaderContext } from '../../../context/LoaderContext';
import { START_LOADER, STOP_LOADER } from '../../../constants';
import { toast } from 'react-toastify';
import { CreateGroupValidation } from '../../../validations/EVCValidation/TagValidation';
import DeleteModalComponent from '../../../components/DeleteModal/DeleteModal';
import { UilTrash } from '@iconscout/react-unicons';
import CustomTooltip from '../../../components/CustomToolTip/CustomTooltip';

// Css module imports
import classes from '../../../styles/Alerts.module.css';
import buttonClasses from '../../../components/Buttons/Button.module.css';
import myClasses from '../../../styles/AllDevices.module.css';
import evcClasses from '../EVCharging.module.css';

const Groups = ({ updateQueryParams, queryParamsData }) => {

    const { dispatch: loaderDispatch } = useContext(LoaderContext);
    const startLoader = (payload) => loaderDispatch({ type: START_LOADER, payload });
    const stopLoader = (payload) => loaderDispatch({ type: STOP_LOADER, payload });

    const { state } = useContext(AuthContext);
    const userRole = LodashGet(state, 'user.userRole');
    const [open, setOpen] = useState(false);
    const [tableData, setTableData] = useState([]);
    const [deleteModal, setDeleteModal] = useState({ status: false, id: true });

    const initialValues = {
        name: '',
        action: false
    }

    useEffect(() => {
        getAllGroups();
    }, [])

    const handleSubmit = (data) => {
        const formData = {
            name: data.name,
            action: data.action,
        }

        if (data._id) {
            EVCService.UpdateGroup(
                data._id,
                formData,
                () => startLoader('updateGroup'),
                () => handleCreateSuccess('Updated'),
                handleError,
                () => stopLoader('updateGroup')
            );
        } else {
            EVCService.CreateGroup(
                formData,
                () => startLoader('createGroup'),
                handleCreateSuccess,
                handleError,
                () => stopLoader('createGroup')
            );
        }
    }

    const handleError = (err) => console.log(err);

    const handleCreateSuccess = (type = 'Created') => {
        getAllGroups();
        setOpen(false);
        toast.success(`Tag Group ${type} Successfully`);
    };

    const getAllGroups = () => {
        EVCService.GetAllGroups(
            () => startLoader('getGroups'),
            handleGetGroupsSuccess,
            handleError,
            () => stopLoader('getGroups')
        );
    };

    const getActions = (id) => {
        return (
            <div className={classes.ActionIcons}>
                <div onClick={() => setDeleteModal({ status: true, id })}>
                    <CustomTooltip content={'Delete'}>
                        <UilTrash className={buttonClasses.ButtonIcon} />
                    </CustomTooltip>
                </div>
            </div>
        );
    };

    const handleTagToggleChange = (item, active) => {
        item.action = active;
        handleSubmit(item)
    }

    const getActive = (item) => {
        return (
            <div className={classes.ActionIcons}>
                <ToggleButtonWithState
                    value={item.action}
                    onChange={(e) => handleTagToggleChange(item, e.target.checked)}
                    warpperStyle={{ marginTop: '0' }}
                />
            </div>
        )
    };

    const handleGetGroupsSuccess = ({ data }) => {
        const processedData = data?.data.map((item) => {
            return {
                ...item,
                active: getActive(item),
                action: getActions(item._id)
            }
        });

        setTableData(processedData);
    }

    const handleDeleteSuccess = (data, id) => {
        toast.success('Tag Group Deleted!');
        getAllGroups();
    };

    const handleDelete = (id) => {
        EVCService.DeleteGroup(
            id,
            () => startLoader('deleteGroup'),
            handleDeleteSuccess,
            handleError,
            () => stopLoader('deleteGroup')
        );
    };

    return (
        <div className={classes.Alert}>
            <div className={classes.TableHeadSection} >
                <div className={evcClasses.HeadingContainer}>
                    <Typography content="Groups" />
                    <div className={myClasses.TableCount}>
                        Total Count :
                        <span>
                            <Typography size="14" content={tableData.length} />
                        </span>
                    </div>
                </div>
                {[USER_ROLE.SUDOER, USER_ROLE.EXPERT].includes(userRole) ? (
                    <div className={classes.CreateButton}>
                        <CreateButton
                            size="medium"
                            text="Create Group"
                            onClick={() => {
                                setOpen(!open);
                            }}
                        />
                    </div>
                ) : null}
            </div>

            <Table
                head={[
                    'Name',
                    [USER_ROLE.SUDOER, USER_ROLE.EXPERT].includes(userRole) ? 'Active' : '',
                    [USER_ROLE.SUDOER, USER_ROLE.EXPERT].includes(userRole) ? 'Action' : ''
                ]}
                keys={[
                    'name',
                    [USER_ROLE.SUDOER, USER_ROLE.EXPERT].includes(userRole) ? 'active' : '',
                    [USER_ROLE.SUDOER, USER_ROLE.EXPERT].includes(userRole) ? 'action' : ''
                ]}
                data={tableData}
            />

            <ModalComponent isOpen={open} setOpen={setOpen} onClose={setOpen}>
                <div className={classes.ModalWidth}>
                    <Formik initialValues={initialValues} validationSchema={CreateGroupValidation} onSubmit={(val, { resetForm }) => { handleSubmit(val, resetForm); }}>
                        {({ errors, touched, values, isValidating, ...props }) => {
                            return (
                                <Form>
                                    <Typography content="Create Group" />
                                    <div>
                                        <div className={classes.InputContainer}>
                                            <div className={classes.FieldControl2 + ' ' + classes.FieldControlGrow}>
                                                <label for="name">
                                                    Name <span className="required">*</span>
                                                </label>
                                                <Input name="name" id="name" />
                                            </div>
                                        </div>
                                        <div className={classes.FieldControl2 + ' ' + classes.FieldControlGrow}>
                                            <ToggleButton label={'Active'} values={values} name="action" id="action" />
                                        </div>
                                        <div className={classes.ButtonContainer}>
                                            <div>
                                                <div
                                                    className="btn-secondary"
                                                    onClick={() => {
                                                        setOpen(false);
                                                    }}
                                                >
                                                    Cancel
                                                </div>
                                            </div>
                                            <div>
                                                <button type="submit" className="btn-primary">
                                                    Submit
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                </Form>
                            );
                        }}
                    </Formik>
                </div>
            </ModalComponent>

            <DeleteModalComponent
                deletefunction={handleDelete}
                opendeleteModal={deleteModal.status}
                setOpenDeleteModal={(status) => setDeleteModal({ id: '', status })}
                deviceId={deleteModal.id}
            ></DeleteModalComponent>
        </div>
    );
};

export default Groups;
