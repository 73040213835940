import * as yup from 'yup';
import { ERRORS } from '../../utils/validationHelper';

export const CreateTagValidation = yup.object().shape({
    name: yup.string().required(ERRORS.required),
    tagID: yup.string().required(ERRORS.required),
    action: yup.bool().required(ERRORS.required),
    groupId: yup.string().optional(),
});

export const CreateGroupValidation = yup.object().shape({
    name: yup.string().required(ERRORS.required),
    action: yup.bool().required(ERRORS.required),
});
