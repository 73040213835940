// Standard library imports
import React, { useState, useEffect, useContext } from 'react';

// External library imports
import { UilImport, UilInfoCircle } from '@iconscout/react-unicons';
import moment from 'moment';

// Internal module imports
import Table from '../../../components/Table/Table';
import { DownloadAsExcel } from '../../../utils/downloadAsExcel';
import Typography from '../../../components/Typography/Typography';
import { momentTimeFormater } from '../../../utils/dateHelper';
import { DropdownComponent } from '../../../components/Inputs/Input';
import { EVCService } from '../../../services/EVCService';
import { START_LOADER, STOP_LOADER } from '../../../constants';
import { LoaderContext } from '../../../context/LoaderContext';
import { CamelCaseToTextCase } from '../../../utils/stringHelper';
import DateRangePicker from '../../../components/Inputs/DateRangePicker/DateRangePicker';
import { truncateNumber } from '../../../utils/numberHelpers';
import CustomTooltip from '../../../components/CustomToolTip/CustomTooltip';
import buttonClasses from '../../../components/Buttons/Button.module.css';
import ModalComponent from '../../../components/ModalComponent/ModalComponent';
import TransactionInfoModal from './TransactionInfoModel';

// Css module imports
import myClasses from '../EVCharging.module.css';
import classes from '../../../styles/AllDevices.module.css';

const LIMIT = 15;
const today = new Date();
const yesterday = new Date(today);
yesterday.setDate(yesterday.getDate() - 1);

const Transactions = ({ instances, updateQueryParams, queryParamsData }) => {

    const { dispatch: loaderDispatch } = useContext(LoaderContext);
    const startLoader = (payload) => loaderDispatch({ type: START_LOADER, payload });
    const stopLoader = (payload) => loaderDispatch({ type: STOP_LOADER, payload });

    const [tableData, setTableData] = useState([]);
    const [totalConsumption, setTotalConsumption] = useState([]);
    const [skip, setSkip] = useState(0);
    const [page, setPage] = useState(0);
    const [totalCount, setTotalCount] = useState(0);
    const [dateRange, setDateRange] = useState({
        startDate: yesterday,
        endDate: today,
    });

    const defaultValue = {
        label: 'All',
        value: 'All'
    };
    const [groups, setGroups] = useState([defaultValue]);
    const [selectedGroup, setSelectedGroup] = useState(defaultValue);

    const [chargers, setChargers] = useState([defaultValue]);
    const [selectedCharger, setSelectedCharger] = useState(defaultValue);
    const [connectors, setConnectors] = useState([defaultValue]);
    const [selectedConnector, setSelectedConnector] = useState(defaultValue);
    const [tags, setTags] = useState([defaultValue]);
    const [selectedTag, setSelectedTag] = useState(defaultValue);
    const [infoModal, setInfoModal] = useState({ status: false, data: '' });

    useEffect(() => {
        if (instances?.length) {
            const processedData = instances.map((charger) => {
                return {
                    label: charger.meta?.serialNumber,
                    value: charger._id,
                    connectors: charger.meta?.connectors,
                    chargerId: charger.meta?.serialNumber
                }
            });

            setChargers([defaultValue, ...processedData]);
        }
    }, [instances]);

    useEffect(() => {
        const processedData = [];
        if (selectedCharger.value !== 'All') {
            for (let i = 1; i <= selectedCharger?.connectors; i++) {
                processedData.push({
                    label: `Connector ${i}`,
                    value: i
                })
            }
        }
        setConnectors([defaultValue, ...processedData]);
        setSelectedConnector(defaultValue);
    }, [selectedCharger.value]);

    useEffect(() => {
        getAllGroups();
    }, []);

    useEffect(() => {
        if (selectedGroup.value !== 'All') {
            getTagsByGroup()
        }
    }, [selectedGroup.value]);

    useEffect(() => {
        fetchTransactionData();
    }, [selectedConnector.value, selectedGroup.value, selectedTag.value, skip]);

    const getAllGroups = () => {
        EVCService.GetAllGroups(
            () => startLoader('getGroups'),
            handleGetGroupsSuccess,
            handleError,
            () => stopLoader('getGroups')
        );
    };

    const getTagsByGroup = () => {
        EVCService.GetTagsByGroup(
            selectedGroup.value,
            () => startLoader('getGroups'),
            handleGetTagSuccess,
            handleError,
            () => stopLoader('getGroups')
        );
    };

    const handleError = (error) => {
        console.log(error);
    }

    const handleGetTagSuccess = ({ data }) => {
        const processedData = data.data.map((item) => {
            return {
                label: item.name,
                value: item.tagID,
            }
        });
        setTags([defaultValue, ...processedData]);
        setSelectedTag(defaultValue);
    }

    const handleGetGroupsSuccess = ({ data }) => {
        const processedData = data.data.map((item) => {
            return {
                label: item.name,
                value: item._id,
            }
        });
        setGroups([defaultValue, ...processedData]);
        setSelectedGroup(defaultValue);
    }

    const fetchTransactionData = () => {
        EVCService.GetAppTransactionData(
            {
                chargerId: selectedCharger?.chargerId ? selectedCharger.chargerId : 'All',
                connectorId: selectedConnector.value,
                groupId: selectedGroup.value,
                tagId: selectedTag.value,
                startDate: moment(dateRange.startDate).tz('Europe/Berlin').format('YYYY-MM-DD'),
                endDate: moment(dateRange.endDate).tz('Europe/Berlin').format('YYYY-MM-DD'),
                limit: LIMIT,
                skip,
            },
            startLoader,
            handleTransactionDataSuccess,
            handleError,
            stopLoader
        );
    };

    const getActions = (data) => {
        return (
            <div className={classes.ActionIcons}>
                <div onClick={() => setInfoModal({ status: true, data })}>
                    <CustomTooltip content={'Info'}>
                        <UilInfoCircle className={buttonClasses.ButtonIcon} />
                    </CustomTooltip>
                </div>
            </div>
        );
    };

    const handleTransactionDataSuccess = ({ data }) => {
        const { transactions, overallConsumption, totalCount } = data?.data;
        const processedData = transactions.map((item) => {
            return {
                tagId: item.idTag,
                chargerId: item.chargerId,
                connectorId: item.connectorId,
                mode: CamelCaseToTextCase(item.mode),
                consumption: item.consumption ? truncateNumber(item.consumption, 3) : 0,
                status: item.status,
                type: item.type ? item.type : '--',
                startTime: item.startTime ? momentTimeFormater(item.startTime).format('YYYY-MM-DD HH:mm:ss') : '--',
                endTime: item.endTime ? momentTimeFormater(item.endTime).format('YYYY-MM-DD HH:mm:ss') : '--',
                action: getActions(item)
            }
        });

        setTableData(processedData);
        setTotalConsumption(overallConsumption ? truncateNumber(overallConsumption, 3) : 0);
        setTotalCount(totalCount);
    };

    const handleExport = () => {
        const excelData = tableData.map((data) => {
            return {
                a: data.tagId,
                b: data.chargerId,
                c: data.connectorId,
                d: data.mode,
                e: data.consumption,
                f: data.status,
                g: data.type,
                h: data.startTime,
                i: data.endTime,
            };
        });
        DownloadAsExcel(excelData, `EV Charging transactions`, ['TAG ID', 'CHARGER ID', 'CONNECTOR ID', 'MODE', 'CONSUMPTION (kWh)', 'STATUS', 'TYPE', 'START TIME (CET)', 'END TIME (CET)']);
    };

    const handlePageChange = (pageno) => {
        setSkip(LIMIT * pageno);
        setPage(pageno);
    };

    return (
        <div className={classes.Ventilation + ' ventilation'}>
            <div className={myClasses.FilterContainer}>
                <div className={myClasses.HeadingContainer}>
                    <div className={classes.FieldControl}>
                        <label>Charger</label>
                        <DropdownComponent
                            name="charger"
                            options={chargers}
                            defaultValue={selectedCharger}
                            onChange={(e) => setSelectedCharger(e)}
                        />
                    </div>

                    <div className={classes.FieldControl}>
                        <label>Connector</label>
                        <DropdownComponent
                            name="connector"
                            options={connectors}
                            defaultValue={selectedConnector}
                            onChange={(e) => setSelectedConnector(e)}
                        />
                    </div>

                    <div className={classes.FieldControl}>
                        <label>Group</label>
                        <DropdownComponent
                            name="groupId"
                            options={groups}
                            defaultValue={selectedGroup}
                            onChange={(e) => setSelectedGroup(e)}
                        />
                    </div>

                    <div className={classes.FieldControl}>
                        <label>Tag ID</label>
                        <DropdownComponent
                            name="tags"
                            options={tags}
                            defaultValue={selectedTag}
                            onChange={(e) => setSelectedTag(e)}
                        />
                    </div>
                </div>

                <div className={myClasses.DatePickers}>
                    <div>
                        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                            <div className={classes.DateLabels}>Start Date</div>
                            <div className={classes.DateLabels} style={{ marginRight: '2.604vw' }}>
                                End Date
                            </div>
                        </div>
                        <DateRangePicker
                            startDate={dateRange.startDate}
                            endDate={dateRange.endDate}
                            onChange={(date) => setDateRange({ startDate: new Date(date.startDate), endDate: new Date(date.endDate) })}
                        />
                    </div>
                    <div
                        className={classes.SubmitButton}
                        onClick={() => {
                            fetchTransactionData();
                            setPage(0);
                        }}
                    >
                        <button className="btn-primary" style={{ marginTop: '1.2vw' }}>
                            Submit
                        </button>
                    </div>
                    <div
                        style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center' }}
                    >
                        <UilImport size="1.8vw" style={{ color: 'var(--color-primary)' }} onClick={() => handleExport()} />
                    </div>
                </div>
            </div>

            <div className={classes.Alert}>
                <div className={myClasses.wholeFilterWrapper}>
                    <div className={myClasses.HeadingContainer}>
                        <Typography content={`Transactions`} />
                        <div className={classes.TableCount}>
                            Total Count :
                            <span>
                                <Typography size="14" content={tableData.length} />
                            </span>
                        </div>
                        <div className={classes.TableCount}>
                            Total Consumption :
                            <span>
                                <Typography size="14" content={`${totalConsumption} kWh`} />
                            </span>
                        </div>
                    </div>
                </div>
                <Table
                    head={['TAG ID', 'CHARGER ID', 'CONNECTOR ID', 'MODE', 'CONSUMPTION (kWh)', 'STATUS', 'TYPE', 'START TIME (CET)', 'END TIME (CET)', 'Action']}
                    keys={['tagId', 'chargerId', 'connectorId', 'mode', 'consumption', 'status', 'type', 'startTime', 'endTime', 'action']}
                    data={tableData}
                    page={page}
                    Pagination={true}
                    limit={LIMIT}
                    handlePageChange={handlePageChange}
                    totalCount={totalCount}
                />
            </div>

            <ModalComponent isOpen={infoModal.status} setOpen={() => setInfoModal({ status: false, data: '' })}>
                <TransactionInfoModal
                    setOpen={() => setInfoModal({ status: false, data: '' })}
                    data={infoModal.data}
                    updateQueryParams={updateQueryParams}
                    queryParamsData={queryParamsData}
                />
            </ModalComponent>
        </div>
    );
};

export default Transactions;
