// Standard library imports
import React from 'react';
// External library imports
import { Form, Formik } from 'formik';
// Internal module imports
import { TextArea } from '../../../components/Inputs/Input';
import ovenClasses from '../Index.module.css';
import Typography from '../../../components/Typography/Typography';

export const Note = ({ note = '' }) => {
    return (
        <div style={{ width: '40vw' }}>
            <Typography content={'Note'} size={14} />
            <div>
                <Formik
                    initialValues={{
                        note: note || '',
                    }}
                    enableReinitialize
                >
                    {({ errors, touched, values, isValidating, ...props }) => (
                        <Form>
                            <div className={ovenClasses.FieldControl}>
                                <TextArea name={`note`} label="Note" disabled={true} />
                            </div>
                        </Form>
                    )}
                </Formik>
            </div>
        </div>
    );
};
