// Standard library imports
import React, { useContext, useEffect, useState } from 'react';

// External library imports
import moment from 'moment-timezone';

// Internal module imports
import Typography from '../../../components/Typography/Typography';
import ChartComponent from '../../../components/ApexCharts/Chart';
import { timeOnly } from '../../../utils/dateHelper';
import { DownloadAsExcel } from '../../../utils/downloadAsExcel';
import TabsComponent from '../../../components/Tabs/Tabs';
import DatePicker from '../../../components/Inputs/DatePicker/DatePicker';
import DownloadButton from '../../../components/Buttons/DownloadButton';
import { START_LOADER, STOP_LOADER } from '../../../constants';
import { LoaderContext } from '../../../context/LoaderContext';
import { EVCService } from '../../../services/EVCService';
import { Capitalize } from '../../../utils/stringHelper';
import { DropdownComponent } from '../../../components/Inputs/Input';

// Css imports
import classes from '../../../styles/Overview.module.css';

const today = new Date();
const yesterday = new Date(today);
yesterday.setDate(yesterday.getDate() - 1);

const Overview = ({ selectedInstance, updateQueryParams, queryParamsData }) => {
    const { dispatch: loaderDispatch } = useContext(LoaderContext);
    const startLoader = (payload) => loaderDispatch({ type: START_LOADER, payload });
    const stopLoader = (payload) => loaderDispatch({ type: STOP_LOADER, payload });

    const [lastStatus, setLastStatus] = useState('--');
    const [date, setDate] = useState(queryParamsData.date ? queryParamsData.date : today);
    const [graphData, setGraphData] = useState([]);
    const [consumptionData, setConsumptionData] = useState([]);
    const [socData, setSocData] = useState([]);

    const [selectedIndex] = useState(queryParamsData.subTab ? queryParamsData.subTab : 0);
    const [connectors, setConnectors] = useState([
        {
            label: 'Connector 1',
            value: 1
        }
    ]);
    const [selectedConnector, setSelectedConnector] = useState({
        label: 'Connector 1',
        value: 1
    });

    useEffect(() => {
        const processedData = [];
        if (selectedInstance?.meta?.connectors) {
            for (let i = 1; i <= selectedInstance?.meta?.connectors; i++) {
                processedData.push({
                    label: `Connector ${i}`,
                    value: i
                })
            }
        }
        setConnectors(processedData);
        if (processedData.length) {
            setSelectedConnector(processedData[0]);
        } else {
            setSelectedConnector({});
        }
    }, [selectedInstance?.meta?.connectors])

    useEffect(() => {
        updateQueryParams('date', date);
    }, [date]);

    useEffect(() => {
        if (selectedInstance?._id && selectedConnector?.value) {
            let filteredParams = {
                chargerId: selectedInstance?.meta?.serialNumber,
                connectorId: selectedConnector.value,
                date: moment(date).tz('Europe/Berlin').format('YYYY-MM-DD'),
            };
            EVCService.Overview(
                filteredParams,
                () => startLoader('getInstanceOverview'),
                handleGetDeviceDataSuccess,
                handleError,
                () => stopLoader('getInstanceOverview')
            );
        }
    }, [selectedInstance?._id, date, selectedConnector?.value]);

    useEffect(() => {
        if (selectedInstance?._id && selectedConnector?.value) {
            EVCService.LastReported(
                {
                    chargerId: selectedInstance?.meta?.serialNumber,
                    connectorId: selectedConnector.value,
                },
                () => startLoader('getOverviewGraphLastReported'),
                handleGetLastReportedSuccess,
                handleError,
                () => stopLoader('getOverviewGraphLastReported')
            );
        }
    }, [selectedInstance?._id, selectedConnector?.value]);

    const handleGetDeviceDataSuccess = ({ data }) => {
        setGraphData(data.data.powerData);
        setConsumptionData(data.data.consumptionData || [{ data: [] }]);
        setSocData(data.data.SocData || [{ data: [] }]);
    };
    const handleGetLastReportedSuccess = ({ data }) => {
        setLastStatus(data?.data?.lastReported);
    };

    const handleExport = (type) => {
        let downloadData = [];
        const headers = ['Date & Time'];
        const consumptions = consumptionData[0]?.data || [];
        const soc = socData[0]?.data || [];

        if (type === 'power') {
            graphData[0]?.data?.length &&
                graphData[0]?.data?.map((item, index) => {
                    let temp = {
                        date: item.x,
                        power: item.y,
                    };
                    if (consumptions?.length) {
                        temp.consumption = consumptions?.[index].y ? consumptions?.[index].y : '--';
                    }
                    downloadData.push(temp);
                });
            headers.push('Power (kW)');
            if (consumptions?.length) {
                headers.push('Consumption (kWh)');
            }
        } else if (type === 'consumption') {
            consumptions?.length &&
                consumptions?.map((item, index) => {
                    let temp = {
                        date: item.x,
                        consumption: item.y,
                    };
                    downloadData.push(temp);
                });
            headers.push('Consumption (kWh)');
        } else if (type === 'soc') {
            soc?.length &&
                soc?.map((item, index) => {
                    let temp = {
                        date: item.x,
                        soc: item.y,
                    };
                    downloadData.push(temp);
                });
            headers.push('SoC (kWh)');
        }

        downloadData?.length &&
            DownloadAsExcel(downloadData, `${selectedInstance?.name} ${Capitalize(type)} Data ${moment(date).format('DD-MM-YYYY')}`, headers);
    };

    const handleError = (err) => console.log(err);

    const tabs = [
        {
            name: 'Power (kW)',
            component: (
                <div className={classes.ChartContainer}>
                    <div>
                        <div className={classes.Actions}>
                            <DropdownComponent defaultValue={connectors[0]} options={connectors} onChange={(e) => setSelectedConnector(e)} />
                            <DatePicker date={date} setDate={setDate} />
                            <DownloadButton size="meduim" onClick={() => handleExport('power')} />
                        </div>
                    </div>
                    <ChartComponent type="line" series={graphData} xFormatter={timeOnly} />
                </div>
            ),
        },
        {
            name: 'Consumption (kWh)',
            component: (
                <div className={classes.ChartContainer}>
                    <div className={classes.Actions}>
                        <div className={classes.FieldControl2}>
                            <DropdownComponent defaultValue={connectors[0]} options={connectors} onChange={(e) => setSelectedConnector(e)} />
                        </div>
                        <DatePicker date={date} setDate={setDate} />
                        <DownloadButton size="meduim" onClick={() => handleExport('consumption')} />
                    </div>
                    <ChartComponent type="area" series={consumptionData} xFormatter={timeOnly} />
                </div>
            ),
        },
        {
            name: 'SoC (kWh)',
            component: (
                <div className={classes.ChartContainer}>
                    <div className={classes.Actions}>
                        <div className={classes.FieldControl2}>
                            <DropdownComponent defaultValue={connectors[0]} options={connectors} onChange={(e) => setSelectedConnector(e)} />
                        </div>
                        <DatePicker date={date} setDate={setDate} />
                        <DownloadButton size="meduim" onClick={() => handleExport('soc')} />
                    </div>
                    <ChartComponent type="line" series={socData} xFormatter={timeOnly} />
                </div>
            ),
        },
    ];

    return (
        <div className={classes.Overview}>
            <div className={classes.Header}>
                <div>
                    <Typography content={selectedInstance?.name} />
                    <div>
                        <Typography subtext content={'Last Reported: ' + (lastStatus || ' --')} />
                    </div>
                </div>
            </div>
            <div className={classes.OverviewBorder}></div>
            <div className={classes.OverviewContent}>
                <TabsComponent tabs={tabs} selectedIndex={selectedIndex} onChangeTab={(index) => updateQueryParams('subTab', index)} />
            </div>
        </div>
    );
};

export default Overview;
