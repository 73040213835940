import { HttpClient } from '../utils/httpClient';

const PATH = {
    tag: '/client/evc/tag',
    group: '/client/evc/group',
    connector: '/client/evc/connector',
    overview: '/client/evc/overview',
    lastReported: '/client/evc/last-reported',
    transaction: '/client/evc/transaction',
    remoteStart: '/client/evc/remote/start',
    remoteStop: '/client/evc/remote/stop',
};

const CreateTag = (body, start, callback, error, next) => {
    start();
    return HttpClient.post(`${PATH.tag}`, body).then(callback).catch(error).finally(next);
};

const UpdateTag = (id, body, start, callback, error, next) => {
    start();
    return HttpClient.patch(`${PATH.tag}/${id}`, body).then(callback).catch(error).finally(next);
};

const GetAllTags = (params, start, callback, error, next) => {
    start();
    return HttpClient.get(`${PATH.tag}`, { params }).then(callback).catch(error).finally(next);
};

const GetTagsByGroup = (id, start, callback, error, next) => {
    start();
    return HttpClient.get(`${PATH.tag}/${id}`).then(callback).catch(error).finally(next);
};

const DeleteTag = (id, start, callback, error, next) => {
    start();
    return HttpClient.delete(`${PATH.tag}/${id}`).then(callback).catch(error).finally(next);
};

const CreateGroup = (body, start, callback, error, next) => {
    start();
    return HttpClient.post(`${PATH.group}`, body).then(callback).catch(error).finally(next);
};

const UpdateGroup = (id, body, start, callback, error, next) => {
    start();
    return HttpClient.patch(`${PATH.group}/${id}`, body).then(callback).catch(error).finally(next);
};

const GetAllGroups = (start, callback, error, next) => {
    start();
    return HttpClient.get(`${PATH.group}`).then(callback).catch(error).finally(next);
};


const DeleteGroup = (id, start, callback, error, next) => {
    start();
    return HttpClient.delete(`${PATH.group}/${id}`).then(callback).catch(error).finally(next);
};

const getLicenseTypeDetails = (id, start, callback, error, next) => {
    start();
    return HttpClient.get(`${PATH.connector}/${id}`).then(callback).catch(error).finally(next);
};


const Overview = (params, start, callback, error, next) => {
    start();
    return HttpClient.get(`${PATH.overview}`, { params }).then(callback).catch(error).finally(next);
};

const LastReported = (params, start, callback, error, next) => {
    start();
    return HttpClient.get(`${PATH.lastReported}`, { params }).then(callback).catch(error).finally(next);
};

const GetAppTransactionData = (params, start, callback, error, next) => {
    start();
    return HttpClient.get(`${PATH.transaction}`, { params }).then(callback).catch(error).finally(next);
};

const FetchTransactionInfo = (id, start, callback, error, next) => {
    start();
    return HttpClient.get(`${PATH.transaction}/${id}`).then(callback).catch(error).finally(next);
};

const RemoteStart = (body, start, callback, error, next) => {
    start();
    return HttpClient.post(`${PATH.remoteStart}`, body).then(callback).catch(error).finally(next);
};

const RemoteStop = (body, start, callback, error, next) => {
    start();
    return HttpClient.post(`${PATH.remoteStop}`, body).then(callback).catch(error).finally(next);
};

export const EVCService = {
    CreateTag,
    UpdateTag,
    GetAllTags,
    GetTagsByGroup,
    CreateGroup,
    UpdateGroup,
    GetAllGroups,
    DeleteTag,
    DeleteGroup,
    getLicenseTypeDetails,
    Overview,
    LastReported,
    GetAppTransactionData,
    FetchTransactionInfo,
    RemoteStart,
    RemoteStop,
};
