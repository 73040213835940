
// Standard library imports
import React from 'react';

// Internal module imports
import DeviceList from './DeviceList';
import Overview from './Overview';

// Css module imports
import classes from '../EVCharging.module.css';

const Details = ({ instances, selectedInstance, setSelectedInstance, updateQueryParams, queryParamsData = {} }) => {

    return (
        <div>
            <div className={classes.DeviceDashboard}>
                <DeviceList
                    instances={instances}
                    setSelectedInstance={setSelectedInstance}
                    updateQueryParams={updateQueryParams}
                    queryParamsData={queryParamsData}
                />
                <Overview
                    selectedInstance={selectedInstance}
                    updateQueryParams={updateQueryParams}
                    queryParamsData={queryParamsData}
                />
            </div>
        </div>
    );
};

export default Details;
